import React from "react";
import { Skeleton, Stack, Avatar, ListItemButton, ListItemAvatar, ListItemText, Typography, Box } from "@mui/material";

const RecentChatSkeleton = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <ListItemButton key={index} sx={{ px: "0" }}>
          <ListItemAvatar
            sx={{
              width: "56px",
            }}
          >
            <Skeleton variant="circular" width={50} height={50} />
          </ListItemAvatar>
          <ListItemText
            sx={{
              pl: "10px",
              width: "60%",
            }}
            primary={
              <Skeleton
                variant="text"
                width="80%"
                height={22}
                sx={{
                  maxWidth: "220px",
                  "@media (max-width: 600px)": {
                    maxWidth: "110px",
                  },
                }}
              />
            }
            secondary={
              <Skeleton
                variant="text"
                width="100%"
                height={18}
                sx={{
                  maxWidth: "220px",
                  "@media (max-width: 600px)": {
                    maxWidth: "110px",
                  },
                }}
              />
            }
          />
          <ListItemText
            sx={{
              width: "calc(40% - 56px)",
            }}
          >
            <Stack alignItems="flex-end">
              <Skeleton variant="text" width="40%" height={16} sx={{ marginBottom: "5px" }} />
              <Skeleton variant="circular" width={24} height={24} />
            </Stack>
          </ListItemText>
        </ListItemButton>
      ))}
    </>
  );
};

export default RecentChatSkeleton;
