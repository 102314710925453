import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const sendMessage = createApi({
    reducerPath: 'sendMessage',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        sendMessage: builder.mutation({
            query: ({ userId , message , groupId}) => ({
                url:`message/send_message`,
                method: 'POST',
                body: { 
                     content: message,
                     chatId: groupId,
                 },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useSendMessageMutation } = sendMessage;
export default sendMessage;
