import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";
import { useSelector } from "react-redux";

const baseUrl = process.env.REACT_APP_base_URL;

const deleteDriveFolder = createApi({
    reducerPath: 'deleteDriveFolder',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        deleteDriveFolder : builder.mutation({
            query: ({id , userId}) => ({
                url: `drive/folder/${id}`,
                method: 'DELETE',
            }),
            transformResponse: (response) =>{ 
                return response;
            }
        }),
    }),
});

export const { useDeleteDriveFolderMutation } = deleteDriveFolder;
export default deleteDriveFolder;

