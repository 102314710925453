import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const quickAccessDocs  = createApi({
    reducerPath: 'quickAccessDocs',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchQuickDocumentList: builder.query({
        query: ({ searchParam}) => ({
          url: `meetings/quick-access-doc?search=${searchParam}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          if(response.data.length > 0){
            return response;
          }
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch Document details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchQuickDocumentListQuery } = quickAccessDocs;
export default quickAccessDocs;
