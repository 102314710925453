import React, { useState } from "react";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import MainCard from "../../commonComponents/MainCard";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { PieChart } from "@mui/x-charts/PieChart";
import { useTheme } from "@emotion/react";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import { useNavigate } from "react-router-dom";
import { useFetchUserOverviewQuery } from "../../redux/api/Users/getUserOverview";
import OverViewSkull from "../Skeleton/OverViewSkull";
import { useSelector } from "react-redux";
const data = [
  { value: 15, label: "12 Investments", color: "#7CDDA3" },
  { value: 9, label: "9 Rejected", color: "#FFB2B2" },
  { value: 18, label: "18 Commitments", color: "#106EED" },
];
const data1 = [
  { value: 5, label: "15 Completed", color: "#7CDDA3" },
  { value: 10, label: "11 Rejected", color: "#FFB2B2" },
  { value: 15, label: "21 In progress", color: "#106EED" },
];

const Overview = ({
  userOverviewData,
  userOverviewLoading,
  userOverviewError,
}) => {
  const theme = useTheme();
  const userType = localStorage.getItem("userType");
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  //Api call
  //   const {
  //     data: userOverviewData,
  //     isLoading: userOverviewLoading,
  //     error: userOverviewError,
  //   } = useFetchUserOverviewQuery();

  // Dropdown Menu
  const [swapped, setSwapped] = useState(false);
  const [buttonText, setButtonText] = useState("See Investment Summary");
  const handleSwap = () => {
    setSwapped(!swapped);
  };
  const navigate = useNavigate();
  const handleOnClick = (path) => {
    navigate(path);
  };

  const totalOverviewDataCount =
    userOverviewData?.data?.invited.length +
    userOverviewData?.data?.rejected.length +
    userOverviewData?.data?.responded.length +
    userOverviewData?.data?.selected.length +
    userOverviewData?.data?.shortlisted.length;
  const overviewData = [
    {
      value: userOverviewData?.data?.invited.length || 0,
      label: `${userOverviewData?.data?.invited.length || 0} ${userType === "solution_provider" ? "Invites" : "Invited"} `,
      color: "#FFB2B2",
    },
    {
      value: userOverviewData?.data?.shortlisted?.length || 0,
      label: ` ${userOverviewData?.data?.shortlisted?.length || 0} In Review `,
      color: "#106EED",
    },
    {
      value: userOverviewData?.data?.responded?.length || 0,
      label: `${userOverviewData?.data?.responded?.length || 0} ${userType === "solution_provider" ? "Accepted" : "Responded"} `,
      color: "#4DD0E1",
    },

    {
      value: userOverviewData?.data?.selected?.length || 0,
      label: ` ${userOverviewData?.data?.selected?.length || 0} ${userType === "solution_provider" ? "Selected" : "Selected"}`,
      color: "#7CDDA3",
    },
    {
      value: userOverviewData?.data?.rejected?.length || 0,
      label: ` ${userOverviewData?.data?.rejected?.length || 0} Rejected `,
      color: "#E57373",
    },
  ];

  return (
    <>
      <MainCard
        content={false}
        sx={{
          backgroundColor: "#ECF6FF",
          border: "none",
          padding: "15px 25px",
          boxShadow: "none",
          borderRadius: "10px",
          // minHeight: userType == "solution_provider"  ? "465px" : "420px",
          minHeight: "420px",
        }}
      >
        {userOverviewLoading ? (
          <OverViewSkull />
        ) : (
          <Box sx={{ p: 0, pb: 0 }}>
            <Stack
              spacing={2}
              direction="row"
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                color="textSecondary"
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  color: "secondary.main",
                  marginBottom: "1rem",
                }}
              >
                Overview
              </Typography>
              {/* For Solution Provide */}
              {/* <div className=''></div> */}
              {userType === "solution_provider" ? (
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {swapped ? (
                    <>
                      <button
                        className="button"
                        style={{
                          color: "#106EED",
                          fontSize: "12px",
                          fontWeight: "600",
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        Solutions
                      </button>
                      {/* <Box
                      sx={{
                        pt: 0.7,
                      }}
                    >
                      <SwapHorizOutlinedIcon
                        sx={{
                          color: "primary.main",
                          fontSize: "15px",
                        }}
                      />
                    </Box>
                    <button
                      className="button"
                      onClick={handleSwap}
                      style={{
                        color: "#A2C2F1",
                        fontSize: "12px",
                        fontWeight: "600",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      Investment
                    </button> */}
                    </>
                  ) : (
                    //   <>
                    //     <button
                    //       className="button"
                    //       style={{
                    //         color: "#106EED",
                    //         fontSize: "12px",
                    //         fontWeight: "600",
                    //         backgroundColor: "transparent",
                    //         border: "none",
                    //       }}
                    //     >
                    //       Investment
                    //     </button>
                    //     <Box
                    //       sx={{
                    //         pt: 0.7,
                    //       }}
                    //     >
                    //       <SwapHorizOutlinedIcon
                    //         sx={{
                    //           color: "primary.main",
                    //           fontSize: "15px",
                    //         }}
                    //       />
                    //     </Box>
                    //     <button
                    //       className="button"
                    //       onClick={handleSwap}
                    //       style={{
                    //         color: "#A2C2F1",
                    //         fontSize: "12px",
                    //         fontWeight: "600",
                    //         backgroundColor: "transparent",
                    //         border: "none",
                    //         cursor: "pointer",
                    //       }}
                    //     >
                    //       Solution
                    //     </button>
                    //   </>
                    <>
                      <button
                        className="button"
                        style={{
                          color: "#106EED",
                          fontSize: "12px",
                          fontWeight: "600",
                          backgroundColor: "transparent",
                          border: "none",
                        }}
                      >
                        Solutions
                      </button>
                    </>
                  )}
                </Stack>
              ) : (
                <>
                  <button
                    className="button"
                    style={{
                      color: "#106EED",
                      fontSize: "12px",
                      fontWeight: "600",
                      backgroundColor: "transparent",
                      border: "none",
                    }}
                  >
                    Requirements
                  </button>
                </>
              )}
            </Stack>
            <Box
              sx={{
                textAlign: "center",
                position: "relative",
                width: "230px",
                ml: "auto",
                mr: "auto",
                [theme.breakpoints.down("sm")]: {
                  width: "240px",
                  ml: "auto",
                  mr: "auto",
                },
              }}
            >
              <Box>
                {userType === "solution_provider" ? (
                  // <>
                  //   {swapped ? (
                  //     <PieChart
                  //       series={[{ data: data1, innerRadius: 74, legend: false }]}
                  //       slotProps={{
                  //         legend: { hidden: true },
                  //       }}
                  //       width={320}
                  //       height={200}
                  //       sx={{
                  //         display: "inline-flex",
                  //       }}
                  //     ></PieChart>
                  //   ) : (
                  //     <PieChart
                  //       series={[{ data, innerRadius: 74, legend: false }]}
                  //       slotProps={{
                  //         legend: { hidden: true },
                  //       }}
                  //       width={320}
                  //       height={200}
                  //       sx={{
                  //         display: "inline-flex",
                  //       }}
                  //     ></PieChart>
                  //   )}
                  // </>
                  <>
                    <PieChart
                      series={[
                        {
                          data: overviewData,
                          innerRadius: 74,
                          legend: false,
                        },
                      ]}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                      width={320}
                      height={200}
                      sx={{
                        display: "inline-flex",
                      }}
                    ></PieChart>
                  </>
                ) : (
                  <>
                    <PieChart
                      series={[
                        {
                          data: overviewData,
                          innerRadius: 74,
                          legend: false,
                        },
                      ]}
                      slotProps={{
                        legend: { hidden: true },
                      }}
                      width={320}
                      height={200}
                      sx={{
                        display: "inline-flex",
                      }}
                    ></PieChart>
                  </>
                )}
              </Box>
              {userType === "solution_provider" ? (
                //   <Box
                //     sx={{
                //       position: "absolute",
                //       top: "50%",
                //       left: "0",
                //       width: "100%",
                //       textAlign: "center",
                //       transform: "translate(0, -50%)",
                //     }}
                //   >
                //     <Typography
                //       variant="h6"
                //       sx={{
                //         textAlign: "center",
                //         color: "#073985",
                //         fontWeight: "700",
                //         fontSize: "2.4rem",
                //         lineHeight: "40px",
                //       }}
                //     >
                //       39
                //     </Typography>
                //     <Typography
                //       variant="h6"
                //       sx={{
                //         textAlign: "center",
                //         fontWeight: "600",
                //         color: "#A2C2F1",
                //         fontSize: "12px",
                //       }}
                //     >
                //       Enquiries
                //     </Typography>
                //   </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "0",
                    width: "100%",
                    textAlign: "center",
                    transform: "translate(0, -50%)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      color: "#073985",
                      fontWeight: "700",
                      fontSize: "2.4rem",
                      lineHeight: "40px",
                    }}
                  >
                    {totalOverviewDataCount || 0}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#A2C2F1",
                      fontSize: "12px",
                    }}
                  >
                    Enquiries
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "0",
                    width: "100%",
                    textAlign: "center",
                    transform: "translate(0, -50%)",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      color: "#073985",
                      fontWeight: "700",
                      fontSize: "2.4rem",
                      lineHeight: "40px",
                    }}
                  >
                    {totalOverviewDataCount || 0}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      fontWeight: "600",
                      color: "#A2C2F1",
                      fontSize: "12px",
                    }}
                  >
                    Requirements
                  </Typography>
                </Box>
              )}
            </Box>
            
              {/* <Box
                sx={{
                  py: 1,
                }}
              >
                {swapped
                  ? data1?.map((entry, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        sx={{
                          maxWidth: "190px",
                          margin: "0 auto",
                          py: 0.7,
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "10px",
                            borderRadius: "10px",
                            backgroundColor: entry.color || "#ccc",
                            marginRight: "7px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "ltheme.main",
                            fontSize: "0.9rem",
                            fontWeight: "600",
                          }}
                        >
                          {entry.label}
                        </Typography>
                      </Box>
                    ))
                  : data?.map((entry, index) => (
                      <Box
                        key={index}
                        display="flex"
                        alignItems="center"
                        sx={{
                          maxWidth: "190px",
                          margin: "0 auto",
                          py: 0.7,
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            height: "10px",
                            borderRadius: "10px",
                            backgroundColor: entry.color || "#ccc",
                            marginRight: "7px",
                          }}
                        />
                        <Typography
                          sx={{
                            color: "ltheme.main",
                            fontSize: "0.9rem",
                            fontWeight: "600",
                          }}
                        >
                          {entry.label}
                        </Typography>
                      </Box>
                    ))}
              </Box> */}
              {userType === "solution_provider" ? (
              <Grid container spacing={2}>
                {overviewData?.map((entry, index) => (
                  <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={isDrawerOpen ? 12 : 6}
                  xl={6}
                  key={index}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Ensures the dot and label align properly
                      justifyContent: isDrawerOpen ? "center" : "center", // Centers the content within the Box
                      gap: isDrawerOpen ? 4 : 2,
                      // mt: 1
                    }}
                  >
                    <div
                      style={{
                        width: "10px",
                        minWidth: "10px",
                        height: "10px",
                        borderRadius: "10px",
                        backgroundColor: entry.color || "#ccc",
                      }}
                    />
                    <Box
                    sx={{
                      minWidth:'90px',
                    }}
                    >
                    <Typography
                      sx={{
                        color: "ltheme.main",
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                    >
                      {entry.label}
                    </Typography>
                    </Box>
                  </Box>
                </Grid>
                
                ))}
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {overviewData?.map((entry, index) => (
                  <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={isDrawerOpen ? 12 : 6}
                  xl={6}
                  key={index}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center", // Ensures the dot and label align properly
                      justifyContent: isDrawerOpen ? "center" : "center", // Centers the content within the Box
                      gap: isDrawerOpen ? 4 : 1
                    }}
                  >
                    <div
                      style={{
                        width: "10px",
                        minWidth: "10px",
                        height: "10px",
                        borderRadius: "10px",
                        backgroundColor: entry.color || "#ccc",
                      }}
                    />
                    <Box
                    sx={{
                      minWidth:'90px',
                      
                    }}
                    >
                    <Typography
                      sx={{
                        color: "ltheme.main",
                        fontSize: "0.9rem",
                        fontWeight: "600",
                      }}
                    >
                      {entry.label}
                    </Typography>
                    </Box>
                  </Box>
                </Grid>
                
                ))}
              </Grid>
            )}
            {userType === "solution_provider" ? (
              // <>
              //   {swapped ? (
              //     <Button
              //       variant="contained"
              //       sx={{
              //         width: "100%",
              //         textTransform: "inherit",
              //         p: "10px",
              //         boxShadow: "none",
              //         "&:hover": {
              //           boxShadow: "none",
              //         },
              //       }}
              //       onClick={() => handleOnClick("/my-solutions")}
              //     >
              //       Manage Solutions
              //     </Button>
              //   ) : (
              //     <Button
              //       variant="contained"
              //       sx={{
              //         width: "100%",
              //         textTransform: "inherit",
              //         p: "10px",
              //         boxShadow: "none",
              //         "&:hover": {
              //           boxShadow: "none",
              //         },
              //       }}
              //       onClick={() => handleOnClick("")}
              //     >
              //       See Investment Summary
              //     </Button>
              //   )}
              // </>
              <>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    mt: 2,
                    textTransform: "inherit",
                    p: "10px",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => handleOnClick("/my-solutions")}
                >
                  {/* <AddOutlinedIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  marginRight: "8px",
                }}
              /> */}
                  Manage Solutions
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    textTransform: "inherit",
                    p: "10px",
                    mt: 2,
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => handleOnClick("/bsi-co-lab")}
                >
                  <AddOutlinedIcon
                    sx={{
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                    }}
                  />
                  Create New Requirement
                </Button>
              </>
            )}
          </Box>
        )}
      </MainCard>
    </>
  );
};

export default Overview;
