import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const createDriveFolder = createApi({
    reducerPath: 'createDriveFolder',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
          // const token = useSelector((state)=> state.user.token)
          const token = store.getState().user.token;
          if (token) {
            headers.set('Authorization', `Bearer ${token}`);
          }
          return headers;
      },
    }),
    endpoints: (builder) => ({
        createFolder: builder.mutation({
            query: (folderData) => ({
                url: 'drive/folder/create',
                method: 'POST',
                body: {
                  name: folderData.folderName,
                  isDocument: folderData.isDocument,
                  parent_id: folderData.parentId || 'null',
                  owner_id: folderData.userId,
                },
              }),
            transformResponse: (response) => {
                // Transform the response if needed
               return response
            },
        }),
    }),
});

export const { useCreateFolderMutation } = createDriveFolder;
export default createDriveFolder;
