import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl =process.env.REACT_APP_base_URL;

const rejectMeeting = createApi({
    reducerPath: 'rejectMeeting',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        rejectMeetingInvitation: builder.mutation({
            query: ({  userId , meetingId }) => ({
                url: `meetings/reject`,
                method: 'POST',
                body: {
                    user_id: userId,
                    meeting_id: meetingId,
                }
            }),
            transformResponse: (response) => {
            return response
            },
        }),
    }),
});

export const { useRejectMeetingInvitationMutation } = rejectMeeting;
export default rejectMeeting;
