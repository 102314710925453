import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { store } from '../../store/store';

const baseUrl = process.env.REACT_APP_base_URL

export const getCityListById = createApi({
    reducerPath: 'getCityListById',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchCityListById: builder.query({
            query: ({cityId}) => ({
                url: `city/country/${cityId}`,
                method: 'GET',
            }),
            transformErrorResponse: (response) => {
                return response.data ? response.data : [];
            },
            refetchOnWindowFocus: true,
            onError: (error) => {
                console.error('Fetch user details error:', error);
            },
        })
    })
})

export const {useFetchCityListByIdQuery} = getCityListById;
