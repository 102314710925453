import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const sendFile = createApi({
  reducerPath: 'sendFile',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // const token = useSelector((state)=> state.user.token)
      const token = store.getState().user.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
  },
  }),
  endpoints: (builder) => ({
    sendFile: builder.mutation({
      query: ({userId , chatFormData}) => {
        return {
          url: `message/document`, // Replace with your API endpoint URL
          method: 'POST',
          body: chatFormData,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useSendFileMutation } = sendFile;
export default sendFile;
