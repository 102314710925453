import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const deleteMeeting = createApi({
    reducerPath: 'deleteMeeting',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        deleteMeeting: builder.mutation({
            query: ({ currentMeetingId , userId}) => ({
                url: `meetings/${currentMeetingId}`,
                method: 'DELETE',
            }),
            transformResponse: (response)=>{
                return response;
            }
        }),
    }),
});

export const { useDeleteMeetingMutation } = deleteMeeting;
export default deleteMeeting;

