import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";

const baseUrl =process.env.REACT_APP_base_URL;

const updateUserProfile = createApi({
    reducerPath: 'updateUserProfile',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateUserProfile: builder.mutation({
            query: ({  userId , formData}) => {
                return{
                url:`user/update`,
                method: 'PUT',
                body: formData,
              }
            },
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdateUserProfileMutation } = updateUserProfile;
export default updateUserProfile;
