import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  ListItemText,
  Typography,
  Card,
  CardContent,
  IconButton,
  FormControlLabel,
  styled,
  InputBase,
  Checkbox,
  Pagination,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MainCard from "../../commonComponents/MainCard";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import { getImages } from "../../commonComponents/const";
import CompareIcon from "@mui/icons-material/Compare";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFetchSolutionListQuery } from "../../redux/api/Requirement Boards/getSolutionList";
import NotFoundPage from "../../utils/Error/error";
import { useDispatch, useSelector } from "react-redux";
import { useFetchUseCaseListQuery } from "../../redux/api/usecase/getUseCase";
import { useFetchIndustryListQuery } from "../../redux/api/Industry/getIndustry";
import { useInviteSolutionProviderMutation } from "../../redux/api/Requirement Boards/inviteSolutionProvider";
import { toast } from "react-toastify";
import CustomLoader from "../../utils/Loader/CustomLoader";
import { hideLoader, showLoader } from "../../redux/features/loader";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  setRefetchKanbanBoard,
  setRefetchRequirementBoard,
} from "../../redux/features/refetch";
import { useWithDrawInvitationMutation } from "../../redux/api/Requirement Boards/withdrawInvitation";
import NoDataScreen from "../../commonComponents/noData";
import InviteSolutionProviderSkull from "../../commonComponents/Kanban/InviteSolutionProviderSkull";
import Modals from "../../commonComponents/Modals";
import LimitExceededModal from "../../commonComponents/LimitExceededModal";
import SubscribeModal from "../../commonComponents/SubscribeModal";
import PaymentSuccess from "../MySubscription/paymentSuccess";
function handleClick(event) {
  event.preventDefault();
}
// Search Box
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#ECF6FF",
  "&:hover": {
    backgroundColor: "#ECF6FF",
  },
  marginRight: "0",
  marginLeft: 0,
  marginBottom: 5,
  width: "100%",
  display: "flex",
  alignItems: "center",
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  right: "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#106EED",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1.9, 0, 1.9, 1.7),
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    fontSize: "12px",
    fontWeight: "500",
  },
}));

const InviteSolutionProvider = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [invitedSolutions, setInvitedSolutions] = React.useState({});
  const solutionDetailUrl = process.env.REACT_APP_SOLUTION_DETAIL_URL;
  // const boardId = useSelector((state)=> state.board.boardId)
  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const boardId = useSelector(
    (state) => state.inviteSolutionProviderSlice.boardId
  );

  // getting userId from localStorage
  const userId = localStorage.getItem("userId");
  // getting data from reduxStore
  const boardDetailsString = localStorage.getItem("boardDetail");
  const currentBoardName = localStorage.getItem("boardName");
  let boardData = {};
  if (boardDetailsString) {
    try {
      const boardDetails = JSON.parse(boardDetailsString);
      boardData = boardDetails?.[0] || {};
    } catch (error) {
      console.error("Error parsing JSON from localStorage:", error);
    }
  }

  const industryId = boardData.industryId || "";
  const useCaseIds = boardData.useCaseId || "";
  const boardName = boardData.boardName || currentBoardName;
  // configure states
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total items fetched from API
  const [searchParams, setSearchParams] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [selectedIndustryId, setSelectedIndustryId] = useState([]);
  const [selectedUseCaseId, setSelectedUseCaseId] = useState([]);
  const [recommendedSolutionChecked, setRecommendedSolutionChecked] = useState(false);
  const [expectedItemsCount, setExpectedItemsCount] = useState(9);
  const planName = useSelector((state) => state.user.planName);
  const solutionsLimit = useSelector((state) => state.user.businessUserPlanDetail.discover_compare_ai_solutions);
  const limitMessage = `Your current plan only support ${solutionsLimit} solution. Please upgrade to higher plan to view and compare more solutions`;
  // limitExceeed modal
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const handleOpenUpgradeModal = () => {
    setOpenUpgradeModal(true);
  };
  const handleCloseUpgradeModal = () => {
    setOpenUpgradeModal(false);
  };
  // Subscribe modal
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const handleOpenSubscribeModal = () => {
    setOpenSubscribeModal(true);
  };
  const handleCloseSubscribeModal = () => {
    setOpenSubscribeModal(false);
  };
  //  PaymentSuccess Modal
  const [openPaymentSuccess , setOpenPaymentSuccess] = useState(false)

  const handleOpenPaymentSuccessModal = ()=>{
    setOpenPaymentSuccess(true);
  }
  const handleClosePaymentSuccessModal = ()=>{
    setOpenPaymentSuccess(false);
     }
  // total solutions per page
  const itemsPerPage = 10;
  const {
    data: {
      solutions: solutionList = [],
      invitedSolutionsId = [],
      invitedSolutionCount = "",
    } = {},
    isLoading: solutionLoading,
    error: solutionError,
    refetch,
  } = useFetchSolutionListQuery({
    userId: userId,
    industryId: selectedIndustryId,
    useCaseIds: selectedUseCaseId,
    limit: itemsPerPage,
    page: currentPage,
    searchValue: searchValue,
    recommended: recommendedSolutionChecked,
    boardId: boardId,
  });
  useEffect(() => {
    if (totalItems && totalItems > 0) {
      setExpectedItemsCount(solutionList.length);
    } else {
      setExpectedItemsCount(9);
    }
  }, [totalItems]);

  const handleInputChange = (event) => {
    setSearchParams(event.target.value);
  };
  const handleSearchSolutionProvider = async (event) => {
    if (event.key === "Enter") {
      const inputValue = event.target.value.trim();
      if (!inputValue) {
        setSearchValue(""); // Reset the searchValue state
        await refetch();
        return;
      }
      setSearchValue(inputValue);
      dispatch(showLoader());
      try {
        // Await refetch and handle search results
        await refetch();
        if (solutionError) {
          const errorMessage =
            solutionList?.message || "Failed to load search result";
          showToastMessage(errorMessage, "error");
        }
      } catch (boardError) {
        // Handle errors if needed
        showToastMessage("An error occurred during search", "error");
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    }
  };

  const handleClearSearch = () => {
    setSearchParams("");
    setSearchValue(""); // Reset search value to empty
    refetch(); // Refetch all data
  };

  const {
    data: IndustryData,
    isLoading: industryLoading,
    error: industryError,
  } = useFetchIndustryListQuery();
  const {
    data: UseCaseData,
    isLoading: useCaseLoading,
    error: useCaseError,
  } = useFetchUseCaseListQuery();
  const [
    inviteSolutionProvider,
    { isLoading: inviteLoading, isError: invitationError },
  ] = useInviteSolutionProviderMutation();
  const [
    withdrawSolutionProvider,
    { isLoading: withdrawLoading, isError: withdrawError },
  ] = useWithDrawInvitationMutation();

  useEffect(() => {
    if (solutionList) {
      solutionList.map((solutions) => {
        if(planName == "lite"){
          setTotalItems(solutionsLimit);
        }else if(planName == "plus"){
          setTotalItems(solutionsLimit);
        }else if (planName == "pro"){
          setTotalItems(solutions.totalCount);
        }
      });
    }
  }, [solutionList]);

  useEffect(() => {
    if (industryId && useCaseIds) {
      refetch();
    }
  }, [industryId, useCaseIds, refetch]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    setOpenSubscribeModal(true);
  };
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const solutionProviderId = solutionList?.solutionProviderId;

  //  filter according to industry id

  const handleCheckboxChange = (event, industryId) => {
    if (event.target.checked) {
      setSelectedIndustryId([...selectedIndustryId, industryId]);
    } else {
      setSelectedIndustryId(
        selectedIndustryId.filter((id) => id !== industryId)
      );
    }
  };
  const handleUseCaseCheckboxChange = (event, useCaseId) => {
    if (event.target.checked) {
      setSelectedUseCaseId([...selectedUseCaseId, useCaseId]);
    } else {
      setSelectedUseCaseId(selectedUseCaseId.filter((id) => id !== useCaseId));
    }
  };

  const isIndustryChecked = (industryId) =>
    selectedIndustryId.includes(industryId);
  const isUseCaseChecked = (useCaseId) => selectedUseCaseId.includes(useCaseId);
  const handleRecommendedSolutionChecked = () => {
    setRecommendedSolutionChecked((prevState) => !prevState);
  };
  //   InviteSolutionProivder
  // const handleInvite = async (id, name, solutionProviderId) => {
  //   const planLimits = {
  //     lite: 1,
  //     plus: 5,
  //     pro: 15,
  //   };
  //   const limit = planLimits[planName];

  //   if (!limit) {
  //     showToastMessage("Invalid plan name", "error");
  //     return;
  //   }

  //   if (invitedSolutionCount < limit) {
  //     try {
  //       const response = await inviteSolutionProvider({
  //         userId: userId,
  //         solutionId: id,
  //         solutionName: name,
  //         boardId: boardId,
  //         solutionProviderId: solutionProviderId,
  //       }).unwrap();

  //       // Ensure response has message property
  //       if (response.statusCode === 200) {
  //         const successMessage = "Solution added in requirement board";
  //         toast.success(successMessage, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //         // Update the invitedSolutions state to indicate this solution is invited
  //         setInvitedSolutions((prevState) => ({
  //           ...prevState,
  //           [id]: true,
  //         }));
  //       }

  //       dispatch(setRefetchKanbanBoard());
  //       dispatch(setRefetchRequirementBoard());
  //       dispatch(showLoader());
  //       refetch();
  //     } catch (error) {
  //       // Handle different error structures
  //       const errorMessage = response.error;
  //       toast.error(errorMessage, {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     } finally {
  //       // Hide loader after refetch is complete
  //       dispatch(hideLoader());
  //     }
  //   } else {
  //     if (planName === "lite") {
  //       setOpenUpgradeModal(true);
  //     } else if (planName === "plus") {
  //       setOpenUpgradeModal(true);
  //     } else if (planName === "pro") {
  //       setOpenUpgradeModal(true);
  //     }
  //   }
  // };
  //   InviteSolutionProivder
  const handleInvite = async (id, name, solutionProviderId) => {
   
      try {
        const response = await inviteSolutionProvider({
          userId: userId,
          solutionId: id,
          solutionName: name,
          boardId: boardId,
          solutionProviderId: solutionProviderId,
        }).unwrap();

        // Ensure response has message property
        if (response.statusCode === 200) {
          const successMessage = "Solution added in requirement board";
          toast.success(successMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // Update the invitedSolutions state to indicate this solution is invited
          setInvitedSolutions((prevState) => ({
            ...prevState,
            [id]: true,
          }));
        }

        dispatch(setRefetchKanbanBoard());
        dispatch(setRefetchRequirementBoard());
        dispatch(showLoader());
        refetch();
      } catch (error) {
        // Handle different error structures
        const errorMessage = response.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
    } 
  


  // Initialize `invitedSolutions` with the fetched API data
  useEffect(() => {
    const initialInvitedSolutions = {};
    invitedSolutionsId.forEach((id) => {
      initialInvitedSolutions[id] = true; // Mark as invited
    });
    setInvitedSolutions(initialInvitedSolutions);
  }, [invitedSolutionsId]); // Run effect when `invitedSolutionsId` changes

  //   withdrawInvitation
  // const handleWithdraw = async (id) => {
  //   const planLimits = {
  //     lite: 1,
  //     plus: 5,
  //     pro: 15,
  //   };
  //   const limit = planLimits[planName];

  //   if (!limit) {
  //     showToastMessage("Invalid plan name", "error");
  //     return;
  //   }

  //   if (invitedSolutionCount < limit) {
  //     try {
  //       const response = await withdrawSolutionProvider({
  //         userId: userId,
  //         solutionId: id,
  //         boardId: boardId,
  //       }).unwrap();

  //       // Ensure response has message property
  //       if (response.statusCode === 200) {
  //         const successMessage = "Invitation withdraw";
  //         toast.success(successMessage, {
  //           position: "top-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: false,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //         // Update the invitedSolutions state to indicate this solution is no longer invited
  //         setInvitedSolutions((prevState) => ({
  //           ...prevState,
  //           [id]: false,
  //         }));
  //       }

  //       dispatch(setRefetchKanbanBoard());
  //       dispatch(setRefetchRequirementBoard());
  //       dispatch(showLoader());
  //       refetch();
  //     } catch (error) {
  //       // Handle different error structures
  //       const errorMessage = response.error;
  //       toast.error(errorMessage, {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: false,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     } finally {
  //       // Hide loader after refetch is complete
  //       dispatch(hideLoader());
  //     }
  //   } else {
  //     if (planName === "lite") {
  //       setOpenUpgradeModal(true);
  //     } else if (planName === "plus") {
  //       setOpenUpgradeModal(true);
  //     } else if (planName === "pro") {
  //       setOpenUpgradeModal(true);
  //     }
  //   }
  // };

   //   withdrawInvitation
   const handleWithdraw = async (id) => {
      try {
        const response = await withdrawSolutionProvider({
          userId: userId,
          solutionId: id,
          boardId: boardId,
        }).unwrap();

        // Ensure response has message property
        if (response.statusCode === 200) {
          const successMessage = "Invitation withdraw";
          toast.success(successMessage, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // Update the invitedSolutions state to indicate this solution is no longer invited
          setInvitedSolutions((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        }

        dispatch(setRefetchKanbanBoard());
        dispatch(setRefetchRequirementBoard());
        dispatch(showLoader());
        refetch();
      } catch (error) {
        // Handle different error structures
        const errorMessage = response.error;
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        // Hide loader after refetch is complete
        dispatch(hideLoader());
      }
  };

  useEffect(()=>{
    if(planName === "lite"){
      setOpenSubscribeModal(true);
    }
  },[])

  useEffect(() => {
    if (solutionLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [solutionLoading, dispatch]);

  const breadcrumbs = [
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to="/material-ui/getting-started/installation/"
      onClick={handleClick}
      style={{
        color: "#2A589C",
        textDecoration: "none",
        fontSize: "1.4rem",
        fontWeight: "700",
        color: "#073985",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.1rem",
        },
      }}
    >
      <Link
        to={`/bsi-co-lab/kanban?board_id=${boardId}`}
        style={{
          color: "#2A589C",
          textDecoration: "none",
          fontSize: "1.4rem",
          fontWeight: "700",
          color: "#073985",
          display: "flex",
          alignItems: "center",
          [theme.breakpoints.down("md")]: {
            fontSize: "1.1rem",
          },
        }}
      >
        <KeyboardBackspaceIcon
          sx={{
            fontSize: "2.1rem",
          }}
          style={{ verticalAlign: "middle", marginRight: "0.3rem" }}
        />
      </Link>
      {boardName}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      sx={{
        color: "#2A589C",
        textDecoration: "none",
        fontSize: "1.4rem",
        fontWeight: "700",
        color: "secondary.main",
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.1rem",
        },
      }}
    >
      Invite
    </Typography>,
  ];

  return (
    <>
      <Box>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="small"
              sx={{
                color: "#2A589C",
                textDecoration: "none",
                fontSize: "2.1rem",
                fontWeight: "700",
                color: "secondary.main",
                verticalAlign: "middle",
                [theme.breakpoints.down("md")]: {
                  fontSize: "1.5rem",
                },
              }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <Grid container mt={3} columnSpacing={2.75}>
        <Grid item xs={12} md={6} lg={2.8}>
          <MainCard
            content={false}
            sx={{ backgroundColor: "#ECF6FF", border: "none", padding: "15px" }}
          >
            <Card
              sx={{
                boxShadow: "none",
                mb: 2,
              }}
            >
              <CardContent
                style={{
                  paddingBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "700",
                      color: "secondary.main",
                      marginBottom: "0",
                    }}
                  >
                    Recommended
                  </Typography>
                </Box>
                <Box>
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={recommendedSolutionChecked}
                          onClick={handleRecommendedSolutionChecked}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "secondary.main",
                            },
                          }}
                        />
                      }
                      label="Recommended Solution"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "0.9rem",
                          fontWeight: "600",
                          color: "secondary.main",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                boxShadow: "none",
                mb: 2,
              }}
            >
              <CardContent
                style={{
                  paddingBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "700",
                      color: "secondary.main",
                      marginBottom: "0",
                    }}
                  >
                    Industries
                  </Typography>
                  <ExpandLessIcon
                    sx={{
                      color: "secondary.main",
                    }}
                  />
                </Box>
                <Box>
                  {IndustryData &&
                    IndustryData.data.map((industry) => (
                      <Box key={industry._id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isIndustryChecked(industry._id)}
                              onChange={(event) =>
                                handleCheckboxChange(event, industry._id)
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "secondary.main",
                                },
                              }}
                            />
                          }
                          label={industry.name}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "secondary.main",
                            },
                          }}
                        />
                      </Box>
                    ))}
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{
                boxShadow: "none",
              }}
            >
              <CardContent
                style={{
                  paddingBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    sx={{
                      fontSize: "1.2rem",
                      fontWeight: "700",
                      color: "secondary.main",
                      marginBottom: "0",
                    }}
                  >
                    Use Cases
                  </Typography>
                  <ExpandLessIcon
                    sx={{
                      color: "secondary.main",
                    }}
                  />
                </Box>
                <Box>
                  {UseCaseData &&
                    UseCaseData?.data.map((usecase) => (
                      <Box key={usecase._id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isUseCaseChecked(usecase._id)}
                              onChange={(event) =>
                                handleUseCaseCheckboxChange(event, usecase._id)
                              }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  color: "secondary.main",
                                },
                              }}
                            />
                          }
                          label={usecase.title}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "secondary.main",
                            },
                          }}
                        />
                      </Box>
                    ))}
                </Box>
              </CardContent>
            </Card>
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6} lg={7.5}>
          <Search
            sx={{
              mb: 2,
            }}
          >
            <StyledInputBase
              placeholder="Search solutions"
              inputProps={{ "aria-label": "search" }}
              value={searchParams}
              onChange={handleInputChange}
              onKeyDown={handleSearchSolutionProvider}
              autoFocus
            />
            {searchParams ? (
              <IconButton
                onClick={handleClearSearch}
                sx={{ position: "absolute", right: 0 }}
              >
                <CancelIcon sx={{ color: "lightSecondary.main" }} />
              </IconButton>
            ) : (
              <SearchIconWrapper>
                <SearchIcon sx={{ color: "lightSecondary.main" }} />
              </SearchIconWrapper>
            )}
          </Search>

          <MainCard
            content={false}
            sx={{
              backgroundColor: "#ECF6FF",
              border: "none",
              padding: "15px",
              "& .MuiPaper-root + .MuiPaper-root": {
                mt: 3,
              },
            }}
          >
            {solutionList && solutionList.length > 0 ? (
              solutionList.map((solution, index) => (
                <Card
                  key={solution.solutionId}
                  sx={{
                    boxShadow: "none",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{
                      p: 0,
                    }}
                    style={{
                      paddingBottom: "0",
                    }}
                  >
                    <Box
                      sx={{
                        px: 2,
                        pt: 4,
                        pb: 6,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <img
                            style={{ width: "154px", height: "41px" }}
                            src={solution.logo}
                          />
                        </Box>
                        <Box>
                          <Link
                            to={`${solutionDetailUrl}${solution.solutionName
                              .toLowerCase()
                              .replace(/ /g, "-")}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                textTransform: "inherit",
                                p: "8px 25px",
                                mr: 2,
                                boxShadow: "none",
                                borderWidth: "2px",
                                fontWeight: "600",
                                "&:hover": {
                                  borderWidth: "2px",
                                },
                              }}
                            >
                              Details
                            </Button>
                          </Link>
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "inherit",
                              p: "10px 25px",
                              boxShadow: "none",
                              fontWeight: "600",
                              "&.Mui-disabled": {
                                backgroundColor: "primary.main", // Change this to the desired color
                                color: "white", // Maintain text color
                                opacity: 1, // Keep the button fully opaque
                                boxShadow: "none", // No shadow for the disabled state
                              },
                            }}
                            disabled={inviteLoading || withdrawLoading}
                            onClick={() =>
                              invitedSolutions[solution.solutionId]
                                ? handleWithdraw(solution.solutionId)
                                : handleInvite(
                                    solution.solutionId,
                                    solution.solutionName,
                                    solution.solutionProviderId
                                  )
                            }
                          >
                            {invitedSolutions[solution.solutionId] ||
                            invitedSolutionsId.includes(solution.solutionId)
                              ? "Withdraw Invitation"
                              : "Invite"}
                          </Button>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          mt: 2,
                        }}
                      >
                        <Box>
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            sx={{
                              fontSize: "1.2rem",
                              fontWeight: "700",
                              color: "secondary.main",
                              mb: 0.5,
                            }}
                          >
                            {solution.solutionName}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "500",
                              color: "secondary.main",
                            }}
                          >
                            {solution.shortOverview}
                          </Typography>
                        </Box>
                        {/* <Box sx={{display:'flex'}}>
                                            <IconButton aria-label="compare" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    width:'34px',
                                                    height:'34px',
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <CompareIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton aria-label="bookmark" size="medium"
                                                sx={{
                                                    backgroundColor: "extraLTheme.main",
                                                    borderRadius: "4px",
                                                    ml: 1,
                                                    width:'34px',
                                                    height:'34px',
                                                    color: "ltheme.main"
                                                }}
                                            >
                                                <BookmarkIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box> */}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        py: 2,
                        px: 5,
                        backgroundColor: "#DBEBF833",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderTop: "solid 1px rgb(16 110 237 / 30%)",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          px: "0",
                          alignItems: "center",
                          width: "110px",
                          height: "30px",
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <Box>
                          <img
                            style={{ display: "block" }}
                            src={getImages("headquarters.png")}
                          />
                        </Box>
                        <ListItemText
                          sx={{
                            pl: "10px",
                            width: "60%",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "secondary.main",
                              lineHeight: "15px",
                            }}
                            variant="h6"
                          >
                            Headquarters
                          </Typography>
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "secondary.main",
                              lineHeight: "15px",
                              width: "90px",
                              wordWrap: "break-word",
                            }}
                          >
                            {solution.headquarters}
                          </Typography>
                        </ListItemText>
                      </Box>
                      <Box
                        sx={{
                          px: "0",
                          alignItems: "center",
                          width: "120px",
                          height: "30px",
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <Box>
                          <img
                            style={{ display: "block" }}
                            src={getImages("industries.png")}
                          />
                        </Box>
                        <ListItemText
                          sx={{
                            pl: "10px",
                            width: "60%",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "secondary.main",
                              lineHeight: "15px",
                              width: "90px",
                              wordWrap: "break-word",
                            }}
                            variant="h6"
                          >
                            Industries
                          </Typography>

                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "lightSecondary.main",
                            }}
                          >
                            {Array.isArray(solution.industryNames)
                              ? solution.industryNames.join(", ")
                              : ""}
                          </Typography>
                        </ListItemText>
                      </Box>
                      <Box
                        sx={{
                          px: "0",
                          alignItems: "center",
                          width: "120px",
                          height: "30px",
                          display: "flex",
                          gap: "8px",
                        }}
                      >
                        <Box>
                          <img
                            style={{ display: "block" }}
                            src={getImages("use-cases.png")}
                          />
                        </Box>
                        <ListItemText
                          sx={{
                            pl: "10px",
                            width: "60%",
                          }}
                        >
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "12px",
                              fontWeight: "700",
                              color: "secondary.main",
                              lineHeight: "15px",
                              width: "90px",
                              wordWrap: "break-word",
                            }}
                            variant="h6"
                          >
                            Use Cases
                          </Typography>
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "lightSecondary.main",
                            }}
                          >
                            {Array.isArray(solution.useCaseNames)
                              ? solution.useCaseNames.join(", ")
                              : ""}
                          </Typography>
                        </ListItemText>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))
            ) : solutionList && totalItems == 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                  width: "100%",
                }}
              >
                <NoDataScreen />
              </Box>
            ) : (
              <>
                {[...Array(expectedItemsCount)].map((_, index) => (
                  <InviteSolutionProviderSkull />
                ))}
              </>
            )}
            <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                // variant="outlined"
                // shape="rounded"
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "primary.main", // Change color of pagination item
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#100345", // Make the selected item background transparent
                    color: "#fff", // Change text color of selected page
                    border: "1px solid #100345", // Add outline to the selected page
                    borderRadius: "20%", // Make the selected page rounded
                  },
                  "& .MuiPaginationItem-root.Mui-selected:hover": {
                    backgroundColor: "#100345", // Keep the background transparent on hover
                  },
                  "& .MuiPaginationItem-page:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.04)", // Change background color of pages on hover
                  },
                  "& .MuiPaginationItem-icon": {
                    color: "primary.main", // Change color of pagination icons
                  },
                  "& .MuiPaginationItem-previousNext": {
                    border: "none", // Remove the outline of left and right icons
                    backgroundColor: "transparent", // Make background transparent
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)", // Add hover effect
                    },
                  },
                }}
              />
            </Box>
          </MainCard>
        </Grid>
      </Grid>
      <Modals
        open={openUpgradeModal}
        handleClose={handleCloseUpgradeModal}
        modalWidth={400}
      >
        <LimitExceededModal
          limitMessage={limitMessage}
          handleClose={handleCloseUpgradeModal}
          showToastMessage={showToastMessage}
        />
      </Modals>
      {/* Subscribe Modal */}
      <Modals
        open={openSubscribeModal}
        handleClose={handleCloseSubscribeModal}
        modalWidth={650}
      >
        <SubscribeModal
          limitMessage={limitMessage}
          setOpenPaymentSuccess = {setOpenPaymentSuccess}
          handleClose={handleCloseSubscribeModal}
          showToastMessage={showToastMessage}
        />
      </Modals>
      <Modals
        open={openPaymentSuccess}
        handleClose={handleClosePaymentSuccessModal}
        modalWidth={450}
      >
        <PaymentSuccess
          handleClose={handleClosePaymentSuccessModal}
        />
      </Modals>
    </>
  );
};

export default InviteSolutionProvider;
