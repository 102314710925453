import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Add, Create } from '@mui/icons-material';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import AddIcon from "@mui/icons-material/Add";

const NoDocumentScreen = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const handleUploadDocument = () => {
        navigate("/bsi-drive");
      };
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100% - 164px)"
            textAlign="center"
            padding={3}
        >
            {/* <img
                src="/path-to-your-image/no-data-illustration.png" // Replace with your image path
                // alt="No Data"
                style={{ width: '200px', height: 200, marginBottom: '20px', background: 'gray', borderRadius: 8 }} // Adjust the size as needed
            /> */}
            <Typography 
            sx={{
                fontSize:'1rem',
              color:"black",
              fontWeight: 600,
            }}
            gutterBottom
            >
                No document uploaded yet
            </Typography>
            <Typography 
            variant="body2" 
            sx={{
                color:"black",
                fontWeight: 500,  
              }}
            gutterBottom>
                You can start by uploading your first document and organizing your workspace !
            </Typography>
            
            <Button
            onClick={handleUploadDocument}
            variant="contained"
            sx={{
              textTransform: "inherit",
              mt:1,
              py: "9px",
              boxShadow: "none",
              px: 5.5,
              "&:hover": {
                boxShadow: "none",
              },
              [theme.breakpoints.down("md")]: {
                px: 2.5,
                width: "100%",
              },
            }}
          >
            {/* <AddIcon
              sx={{
                fontSize: "20px",
                marginRight: "8px",
              }}
            /> */}
            Upload Document
          </Button>
            
        </Box>
    );
};

export default NoDocumentScreen;
