import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MainCard from "../../commonComponents/MainCard";
import { useTheme } from "@emotion/react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { getImages } from "../../commonComponents/const";
// Date
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Overview from "../../commonComponents/Dashboard/Overview";
import RecentEnquiries from "../../commonComponents/Dashboard/RecentEnquiries";
import RecentChats from "../../commonComponents/Dashboard/RecentChats";
import ImportantDocuments from "../../commonComponents/Dashboard/ImportantDocuments";
import { useNavigate } from "react-router-dom";
import Modals from "../../commonComponents/Modals";
import LimitExceededModal from "../../commonComponents/LimitExceededModal";
import SubscribeModal from "../../commonComponents/SubscribeModal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFetchUpcomingMeetingListQuery } from "../../redux/api/Meetings/upComingMeetingList";
import UpComingMeetingSkeleton from "../../commonComponents/Skeleton/UpComingMeetingSkull";
import { useFetchUserOverviewQuery } from "../../redux/api/Users/getUserOverview";
import { getUser, setBusinessUserPlanDetail, setPlanName } from "../../redux/features/userSlice";
import { useFetchUserDetailsQuery } from "../../redux/api/Users/getUser";

const Dashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDrawerOpen = useSelector((state) => state.drawer.open);
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // States
  const planName = useSelector((state) => state.user.planName);
  const createRequirementLimit = useSelector((state) =>state.user?.businessUserPlanDetail?.bsi_colab_requirement_creation_wizard);
  const createRequirementLink = process.env.REACT_APP_CREATE_REQUIREMENT_URL;
  const limitMessage = `Your current plan only support limited features. Please upgrade to higher plan to use all features`;

  //   Api calls
  // const { data: userData, error, isLoading } = useFetchUserDetailsQuery();
  // const defaultUserType = "user";
  // const userType = userData?.userType || defaultUserType;
  // useEffect(() => {
  //   if (userData ) {
  //     dispatch(getUser(userData));
  //     dispatch(setPlanName(userData?.subscription?.planSlug || userData?.subscription?.plan_data?.plan_slug || ""))
  //     if(planName == "lite"){
  //       dispatch(setBusinessUserPlanDetail(userData?.subscription?.planValues|| []));
  //     }else if(planName == "plus" || "pro"){
  //       dispatch(setBusinessUserPlanDetail(userData?.subscription?.plan_data?.plan_values || []));
  //     }
  //     localStorage.setItem("userId", userData.id );
  //     localStorage.setItem("userType", userData.userType || defaultUserType);
  //     localStorage.setItem("userName" , `${userData.firstName} ${userData.lastName}` )
  //     localStorage.setItem("avatar" , userData?.logo )
  //   }
  // }, [userData, dispatch]);
  
  const {
    data: upcomingMeetingList,
    isLoading: upcomingMeetingLoading,
    error: upcomingMeetingError,
    refetch: refetchUpComingList,
  } = useFetchUpcomingMeetingListQuery({
    searchParam: "",
  });
  const {
    data: userOverviewData,
    isLoading: userOverviewLoading,
    error: userOverviewError,
    refetch: refetchOverviewData,
  } = useFetchUserOverviewQuery();

  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };
  const getMonthName = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
  };
  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(date);
  };

  const getDayOfMonth = (dateString) => {
    const date = new Date(dateString);
    return date.getDate();
  };

  const handleViewAllClick = (path) => {
    navigate(path);
  };
  const handleOnClick = (path) => {
    navigate(path);
  };
  // limitExceeed modal
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const handleOpenUpgradeModal = () => {
    setOpenUpgradeModal(true);
  };
  const handleCloseUpgradeModal = () => {
    setOpenUpgradeModal(false);
  };
  // Subscribe modal
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const handleOpenSubscribeModal = () => {
    setOpenSubscribeModal(true);
  };
  const handleCloseSubscribeModal = () => {
    setOpenSubscribeModal(false);
  };

//   const handleCreateRequirement = () => {
//     if (!createRequirementLimit) {
//       showToastMessage("Invalid plan name", "error");
//       return;
//     }

//     if (boardData?.length < createRequirementLimit) {
//       window.location.href = createRequirementLink;
//     } else {
//       if (planName === "lite") {
//         setOpenUpgradeModal(true);
//       } else if (planName === "plus") {
//         setOpenUpgradeModal(true);
//       } else if (planName === "pro") {
//         setOpenUpgradeModal(true);
//       }
//     }
//   };

  useEffect(() => {
    if (planName === "lite") {
      setOpenSubscribeModal(true);
    }
    refetchOverviewData();
    refetchUpComingList();
  }, []);

  return (
    <>
      {/* <Layout> */}
      <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{py:2}}>
        <Grid
          item
          xs={12}
          md={12}
          lg={8}
          sx={{
            "@media (max-width:1600px) and (min-width:1401px)": {
              flexBasis: "72%",
              maxWidth: "72%",
            },
            "@media (max-width:1400px) and (min-width:1200px)": {
              flexBasis: "65%",
              maxWidth: "65%",
            },
            "@media (max-width:750px) and (min-width:450px)": {
              paddingLeft:'23px !important'
            },
          }}
        >
          <Grid container rowSpacing={2.75} columnSpacing={2.75}>
            <Grid
              item
              xs={12}
              md={5}
              lg={4}
              sx={{
                "@media (max-width:1600px) and (min-width:1401px)": {
                  flexBasis: "40%",
                  maxWidth: "40%",
                },
                "@media (max-width:1400px) and (min-width:1200px)": {
                  flexBasis: "45%",
                  maxWidth: "45%",
                },
              }}
            >
              <Overview userOverviewData={userOverviewData} userOverviewLoading={userOverviewLoading} userOverviewError={userOverviewError}/>
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              lg={8}
              sx={{
                "@media (max-width:1600px) and (min-width:1401px)": {
                  flexBasis: "60%",
                  maxWidth: "60%",
                },
                "@media (max-width:1400px) and (min-width:1200px)": {
                  flexBasis: "55%",
                  maxWidth: "55%",
                },
              }}
            >
              <RecentEnquiries userOverviewData={userOverviewData} userOverviewLoading={userOverviewLoading} userOverviewError={userOverviewError}/>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                "@media (max-width:1600px) and (min-width:1200px)": {
                  flexBasis: "45%",
                  maxWidth: "45%",
                },
              }}
            >
              <RecentChats setOpenUpgradeModal={setOpenUpgradeModal} setOpenSubscribeModal={setOpenSubscribeModal} />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              sx={{
                "@media (max-width:1600px) and (min-width:1200px)": {
                  flexBasis: "55%",
                  maxWidth: "55%",
                },
              }}
            >
              <ImportantDocuments showToastMessage={showToastMessage}/>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={4}
          sx={{
            "@media (max-width:1600px) and (min-width:1401px)": {
              flexBasis: "28%",
              maxWidth: "28%",
            },
            "@media (max-width:1400px) and (min-width:1200px)": {
              flexBasis: "35%",
              maxWidth: "35%",
            },
          }}
        >
          <MainCard
            content={false}
            sx={{
              backgroundColor: "#ECF6FF",
              border: "none",
              padding: "15px 25px",
              [theme.breakpoints.down("md")]: {
                padding: "15px 15px",
              },
            }}
          >
            <Box sx={{ p: 0, pb: 0 }}>
              <Stack
                spacing={2}
                sx={{
                  flexDirection: "inherit",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "0",
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "600",
                    color: "secondary.main",
                    marginBottom: "1rem",
                  }}
                >
                  Calendar
                </Typography>
              </Stack>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  sx={{
                    width: "100%",
                    minHeight: "350",
                    "& .MuiDayCalendar-weekDayLabel": {
                      fontSize: "0.9rem",
                      width: "42px",
                      height: "42px",
                      color: "ltheme.main",
                      fontWeight: "600",
                      "@media (max-width:1600px)": {
                        width: "35px",
                        height: "35px",
                      },
                      "@media (max-width:1320px)": {
                        width: "32px",
                        height: "32px",
                      },
                      "@media (max-width:767px)": {
                        width: "42px",
                        height: "42px",
                      },
                      "@media (max-width:400px)": {
                        width: "36px",
                        height: "36px",
                      },
                    },
                    "& .MuiPickersDay-root": {
                      fontSize: "0.9rem",
                      width: "42px",
                      height: "42px",
                      color: "secondary.main",
                      fontWeight: "600",
                      "@media (max-width:1600px)": {
                        width: "35px",
                        height: "35px",
                      },
                      "@media (max-width:1320px)": {
                        width: "32px",
                        height: "32px",
                      },
                      "@media (max-width:767px)": {
                        width: "42px",
                        height: "42px",
                      },
                      "@media (max-width:400px)": {
                        width: "36px",
                        height: "36px",
                      },
                    },
                    "& .MuiPickersDay-today": {
                      background: "#106EED",
                      border: "none",
                      color: "#fff",
                    },
                  }}
                />
              </LocalizationProvider>
              {/* Upcoming Meetings */}
              <Stack
                spacing={2}
                sx={{
                  flexDirection: "inherit",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  mb: 2,
                  mt: 2,
                }}
              >
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "600",
                    color: "secondary.main",
                    // marginBottom: "1rem",
                  }}
                >
                  Upcoming Meetings
                </Typography>

                <Button
                  variant="text"
                  onClick={() => handleViewAllClick("/meetings")}
                  sx={{
                    fontSize: "12px",
                    fontWeight: "600",
                    textTransform: "inherit",
                    marginTop:'0px !important'
                  }}
                >
                  View all{" "}
                  <ArrowForwardIosOutlinedIcon
                    sx={{
                      marginLeft: "5px",
                      fill: "primary.main",
                      width: "12px",
                    //   height: "12px",
                    }}
                  />
                </Button>
              </Stack>

              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  textTransform: "inherit",
                  padding: "10px",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                  },
                }}
              onClick={()=>handleOnClick("/meetings")}
              >
                <VideocamOutlinedIcon
                  sx={{ width: "20px", height: "20px", marginRight: "8px" }}
                />{" "}
                Schedule New Meeting
              </Button>

              <List
                component="nav"
                sx={{
                  px: 0,
                  py: 0,
                  minHeight: isDrawerOpen ? "440px" :"390px",
                  "& .MuiListItemButton-root": {
                    py: 1.5,
                    // '& .MuiAvatar-root': avatarSX,
                    // '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                  },
                }}
              >
                 {upcomingMeetingList?.data?.length > 0 ? (
                  upcomingMeetingList?.data?.slice(0,2).map((monthData) => (
                <Grid container mt={0.5} rowSpacing={1.9} columnSpacing={1} key={monthData?._id} >
                  <Grid item xs={12} md={2} lg={2}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "ltheme.main",
                        lineHeight: "20px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginTop: "12px",
                      }}
                      variant="subtitle1"
                    >
                      {getYear(monthData.month)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.3rem",
                        fontWeight: "600",
                        color: "ltheme.main",
                        lineHeight: "20px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginTop: "4px",
                      }}
                      variant="subtitle1"
                    >
                      {getMonthName(monthData.month)}
                    </Typography>
                  </Grid>
                
                  <Grid item xs={12} md={10} lg={10}  >
                  {monthData?.meetings?.slice(0,3).map((meetingList , index) => (
                   <ListItem
                   key={meetingList._id}
                   sx={{
                     minHeight: "40px",
                     alignItems: "center",
                     py: "5px",
                     background: "#FFFFFF",
                     borderRadius: "10px",
                     marginTop: "8px",
                   }}
                 >
                   <ListItemAvatar
                     sx={{
                       display: "flex",
                       flexDirection: "column",
                       marginRight: "7px",
                     }}
                   >
                     <Avatar
                       sx={{
                         bgcolor: "lightSecondary.main",
                         px: 2.8,
                         fontSize: "1rem",
                         fontWeight: "700",
                         width: "45px",
                         height: "40px",
                         borderRadius: "10px",
                         display: "flex",
                         flexDirection: "column",
                         justifyContent: "center",
                         alignItems: "center",
                         lineHeight: "1.2",
                         [theme.breakpoints.down("md")]: {
                           width: "30px",
                           height: "30px",
                           fontSize: "0.6rem",
                         },
                       }}
                       variant="rounded"
                     >
                       {/* {meetingList.title
                       .split(" ")
                       .slice(0, 3)
                       .map((word) => word.charAt(0).toUpperCase())
                       .join("")} */}
                       <span>{getDayOfWeek(meetingList.date)}</span>
                       <span>{getDayOfMonth(meetingList.date)}</span>
                     </Avatar>
                   </ListItemAvatar>

                   <Box sx={{ width: "100%" }}>
                     <ListItemText
                       primary={
                         <Typography
                           noWrap
                           sx={{
                             fontSize: "0.9rem",
                             fontWeight: "600",
                             color: "secondary.main",
                             lineHeight: "20px",
                           }}
                           variant="h6"
                         >
                           {meetingList.title}
                         </Typography>
                       }
                       secondary={
                           <Typography
                             noWrap
                             variant="body2"
                             sx={{
                               fontSize: "12px",
                               fontWeight: "500",
                               color: "ltheme.main",
                               width: "100%",
                             }}
                           >
                             {meetingList.start_time} -{" "}
                             {meetingList.end_time}
                           </Typography>
                           
                       }
                     />
                   </Box>
                 </ListItem>
                    ))}
                  </Grid>
                </Grid>
                ))
            ) : (
              
                upcomingMeetingLoading ? 
                (
                    <UpComingMeetingSkeleton/>
                ):
                (
           <Grid item xs={12}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    color:"black",
                    textAlign: "center",
                    py: "45%",
                    [theme.breakpoints.down("lg")]: {
                      py: "100%",
                    },
                    [theme.breakpoints.down("md")]: {
                      py: "35%",
                    },
                    [theme.breakpoints.down("sm")]: {
                      py: "65%",
                    },
                  }}
                >
                  You have no meetings scheduled !
                </Typography>
              </Grid>
                )
            )}
                {/* <Grid container mt={0.5} rowSpacing={1.9} columnSpacing={1}>
                  <Grid item xs={12} md={2} lg={2}>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "ltheme.main",
                        lineHeight: "20px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginTop: "8px",
                      }}
                      variant="subtitle1"
                    >
                      THU
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "600",
                        color: "ltheme.main",
                        lineHeight: "20px",
                        textAlign: "center",
                        textTransform: "uppercase",
                        marginTop: "4px",
                      }}
                      variant="subtitle1"
                    >
                      23
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10} lg={10}>
                    <ListItem
                      sx={{
                        minHeight: "40px",
                        alignItems: "center",
                        py: "5px",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        marginTop: "8px",
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          display: "inline-block",
                          marginRight: "7px",
                        }}
                      >
                        <img
                          style={{ display: "block" }}
                          src={getImages("image_172.png")}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "secondary.main",
                              lineHeight: "20px",
                            }}
                            variant="h6"
                          >
                            Project Discussion
                          </Typography>
                        }
                        secondary={
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "ltheme.main",
                              width: "100%",
                              mt: 0.5,
                            }}
                          >
                            09:45 AM - 10:45 AM
                          </Typography>
                        }
                      />
                      <AvatarGroup
                        max={3}
                        sx={{ ml: "auto", height: 24, overflow: "visible" }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={getImages("1.jpg")}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Avatar
                          alt="Remy Sharp"
                          src={getImages("2.jpg")}
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </ListItem>
                    <ListItem
                      sx={{
                        minHeight: "40px",
                        alignItems: "center",
                        py: "5px",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        marginTop: "8px",
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          display: "inline-block",
                          marginRight: "7px",
                        }}
                      >
                        <img
                          style={{ display: "block" }}
                          src={getImages("image_172.png")}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "secondary.main",
                              lineHeight: "20px",
                            }}
                            variant="h6"
                          >
                            Solution Enquiries
                          </Typography>
                        }
                        secondary={
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "ltheme.main",
                              width: "100%",
                              mt: 0.5,
                            }}
                          >
                            11:00 AM - 11:30 AM
                          </Typography>
                        }
                      />
                      <AvatarGroup
                        max={3}
                        sx={{ ml: "auto", height: 24, overflow: "visible" }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={getImages("1.jpg")}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Avatar
                          alt="Remy Sharp"
                          src={getImages("2.jpg")}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Avatar
                          alt="Remy Sharp"
                          src={getImages("3.jpg")}
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </ListItem>
                    <ListItem
                      sx={{
                        minHeight: "40px",
                        alignItems: "center",
                        py: "5px",
                        background: "#FFFFFF",
                        borderRadius: "10px",
                        marginTop: "8px",
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          display: "inline-block",
                          marginRight: "7px",
                        }}
                      >
                        <img
                          style={{ display: "block" }}
                          src={getImages("image_172.png")}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            noWrap
                            sx={{
                              fontSize: "0.9rem",
                              fontWeight: "600",
                              color: "secondary.main",
                              lineHeight: "20px",
                            }}
                            variant="h6"
                          >
                            Solution Enquiries
                          </Typography>
                        }
                        secondary={
                          <Typography
                            noWrap
                            variant="body2"
                            sx={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "ltheme.main",
                              width: "100%",
                              mt: 0.5,
                            }}
                          >
                            11:00 AM - 11:30 AM
                          </Typography>
                        }
                      />
                      <AvatarGroup
                        max={3}
                        sx={{ ml: "auto", height: 24, overflow: "visible" }}
                      >
                        <Avatar
                          alt="Remy Sharp"
                          src={getImages("1.jpg")}
                          sx={{ width: 24, height: 24 }}
                        />
                        <Avatar
                          alt="Remy Sharp"
                          src={getImages("2.jpg")}
                          sx={{ width: 24, height: 24 }}
                        />
                      </AvatarGroup>
                    </ListItem>
                  </Grid>
                </Grid> */}
              </List>
            </Box>
          </MainCard>
        </Grid>
      </Grid>
      <Modals
        open={openUpgradeModal}
        handleClose={handleCloseUpgradeModal}
        modalWidth={400}
      >
        <LimitExceededModal
          handleClose={handleCloseUpgradeModal}
          showToastMessage={showToastMessage}
          limitMessage={limitMessage}
        />
      </Modals>
      {/* Subscribe Modal */}
      <Modals
        open={openSubscribeModal}
        handleClose={handleCloseSubscribeModal}
        modalWidth={650}
      >
        <SubscribeModal
          handleClose={handleCloseSubscribeModal}
          showToastMessage={showToastMessage}
          limitMessage={limitMessage}
        />
      </Modals>
    </>
  );
};

export default Dashboard;
