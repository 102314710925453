import React from 'react';
import Modal from '@mui/material/Modal';
import { CloseOutlined } from '@ant-design/icons';
import { Box, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: "20px",
    p: 4,
    maxHeight: '95vh', // Maximum height for the modal
    overflow: 'hidden', // Prevent overflow for the entire modal
};

const Modals = ({ children, open, handleClose, modalWidth }) => {
    const theme = useTheme();

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus
            disableAutoFocus
        >
            <Box
                sx={{
                    ...style,
                    width: modalWidth + "px",
                    outline: "none",
                    [theme.breakpoints.down('sm')]: {
                        width: 'calc(100% - 30px)',
                    },
                }}
            >
                {handleClose && (
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: "10px",
                            top: "5px",
                        }}
                    >
                        <CloseOutlined style={{ color: "#2A589C" }} />
                    </IconButton>
                )}
                {/* Scrollable Content */}
                <Box
                    sx={{
                        maxHeight: 'calc(95vh - 40px)', 
                        overflowY: 'auto', 
                        p:'8px',
                        "&::-webkit-scrollbar": {
                      opacity: 0,
                      width: "6px",
                    },
                    "s:hover": {
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#9C9C9C",
                        borderRadius: "4px",
                      },
                    },
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Modal>
    );
};

// Define PropTypes
Modals.propTypes = {
    children: PropTypes.node.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    modalWidth: PropTypes.number.isRequired,
};

export default Modals;
