import React, { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Divider,
  Grid,
  ListItemAvatar,
  List,
  ListItem,
  ListItemIcon,
} from "@mui/material";
import { getImages } from "./const";
import CheckIcon from "@mui/icons-material/Check";
import { useUpgradeSubscriptionPlanMutation } from "../redux/api/MySubscription/upgradePlan";
import { setRefetchUserData } from "../redux/features/refetch";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../redux/features/loader";
import { useNavigate } from "react-router-dom";

const SubscribeModal = ({ handleClose, showToastMessage , limitMessage , setOpenPaymentSuccess}) => {
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const BusinessUserPlusFeatures = [
    "Number of users who can access - up to 5",
    "BSI-Colab Requirement Creation - 5",
    "BSI-Colab Messaging - Available",
    "BSI-Colab Drive - 1 GB",
  ]
  const BusinessUserProFeatures = [
    "Number of users who can access - Unlimited",
    "BSI-Colab Requirement Creation - Unlimited",
    "BSI-Colab Messaging - Available",
    "BSI-Colab Drive - 10 GB",
  ]
  const SolutionProviderPlusFeatures = [
    "Number of users who can access - up to 5",
    "Featured Listing: Silver",
    "Number of Solutions: up to  5 ",
    "BSI-Colab Drive - 1 GB",
  ]
  const SolutionProviderProFeatures = [
    "Number of users who can access - Unlimited",
    "Featured Listing: Gold",
    "Number of Solutions: Unlimited",
    "BSI-Colab Drive - 10 GB",
  ]


//   const [upgradeSubscriptionPlan, { isLoading: upgradePlanLoading , isError: upgradePlanError  }] =useUpgradeSubscriptionPlanMutation();
//   const upgradeSubscriptionToPlus = async ()=>{
//     try {
//       const response = await upgradeSubscriptionPlan({
//           userId,
//           upgradePlanName: "plus",
//           userType,
//       }).unwrap();
  
//       if (response.statusCode === "500") {
//         showToastMessage("Plan Upgrade Failed");
//       }
//       const successMessage =  "Plan Upgraded";
//       showToastMessage(successMessage, "success");
//       dispatch(setRefetchUserData());
//       setOpenPaymentSuccess(true);
//       handleClose();
//   } catch (error) {
//       const errorMessage = error?.message || "Plan Upgrade Failed";
//       showToastMessage(errorMessage, "error");
//   }
// }

//   const upgradeSubscriptionToPro = async ()=>{
//     try {
//       const response = await upgradeSubscriptionPlan({
//           userId,
//           upgradePlanName: "pro",
//           userType,
//       }).unwrap();
  
//       if (response.statusCode === "500") {
//           showToastMessage("Plan Upgrade Failed");
//       }
//       const successMessage =  "Plan Upgraded";
//       showToastMessage(successMessage, "success");
//       dispatch(setRefetchUserData());
//       setOpenPaymentSuccess(true);
//       handleClose();
//   } catch (error) {
//       const errorMessage = error?.message || "Plan Upgrade Failed";
//       showToastMessage(errorMessage, "error");
//   }
//   }
  const upgradeSubscription = ()=>{
    navigate('/manage-subscription')
  }

  // useEffect(() => {
  //   if (upgradePlanLoading) {
  //     dispatch(showLoader());
  //   } else {
  //     dispatch(hideLoader());
  //   }
  // }, [upgradePlanLoading, dispatch]);
  
  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     minHeight: "100vh",
    //     backgroundColor: "#f5f5f5",
    //     padding: 2,
    //   }}
    // >
    //   <Card
    //     sx={{
    //       maxWidth: 800,
    //       width: "100%",
    //       padding: 4,
    //       borderRadius: 3,
    //       boxShadow: 3,
    //       backgroundColor: "#ffffff",
    //     }}
    //   >
    <Box
      sx={{
        //   maxWidth: 800,
        width: "100%",
        padding: 1,
        //   borderRadius: 3,
        //   boxShadow: 3,
        backgroundColor: "#ffffff",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ListItemAvatar
          sx={{
            textAlign: "center",
            minWidth: "30px",
            mixBlendMode: "multiply",
          }}
        >
          <img
            style={{
              maxWidth: "55px",
              maxHeight: "35px",
              display: "block",
            }}
            src={getImages("crown.png")}
            alt="subscribe"
          />
        </ListItemAvatar>
      </Box>
      <Box textAlign="center" mb={3} mt={3}>
        <Typography 
        sx={{
          fontSize: '1.3rem',
          color: 'secondary.main',
          fontWeight: '700',
        }}
        >
          Upgrade to premium
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.9rem",
            color: "lightSecondary.main",
            // fontWeight: "500",
          }}
          mt={1}
        >
          {limitMessage}
        </Typography>
      </Box>

      {/* <Divider /> */}
      <Grid container spacing={2} mb={2}>
        {/* Demo Plan */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              // border: "1px solid #ddd",
              borderRadius: 2,
              boxShadow: 1,
              height: "100%",
              padding: 1,
            }}
          >
            <CardContent>
              <Typography variant="h5"
                sx={{
                  fontSize: '1.3rem',
                  color: 'secondary.main',
                  fontWeight: '700',
                }}
               >
                Plus
              </Typography>
              {/* <Typography
                variant="body2"
                sx={{
                  fontSize: "0.9rem",
                  color: "lightSecondary.main",
                  // fontWeight: "500",
                }}
                mt={1}
                mb={2}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique.
              </Typography>
              <Box>
                <List sx={{display:'flex' , flexDirection:'column' , gap:1}}>
                  <ListItem
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <CheckIcon sx={{ color: "#5bb450" , fontSize:'1.5rem' , fontWeight:700 }} />
                    </ListItemIcon>
                    <Typography 
                    variant="body1"
                    sx={{
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      // fontWeight: "500",
                    }}
                    >
                      Limited Users Access
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <CheckIcon sx={{ color: "#5bb450" , fontSize:'1.5rem' , fontWeight:700  }} />
                    </ListItemIcon>
                    <Typography 
                    variant="body1"
                    sx={{
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      // fontWeight: "500",
                    }}
                    >
                      Limited Requirement Creation
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <CheckIcon sx={{color: "#5bb450" , fontSize:'1.5rem' , fontWeight:700  }} />
                    </ListItemIcon>
                    <Typography 
                    variant="body1"
                    sx={{
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      // fontWeight: "500",
                    }}
                    >
                      Limited Drive Space
                    </Typography>
                  </ListItem>
                </List>
              </Box> */}
              <Box sx={{mt:2}}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 700,
                          textAlign: "left",
                          fontSize: "0.9em",
                          color: "secondary.main",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Features Included:
                      </Typography>
                      {
                        userType == "solution_provider" ?
                        (
                          <ul style={{ textAlign: "left", padding: "0 20px" }}>
                          {SolutionProviderPlusFeatures.map((feature, i) => (
                            <li key={i}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.9em",
                                  fontWeight: 400,
                                  lineHeight: "1.5em",
                                  color: "lightSecondary.main",
                                  mt: 1,
                                }}
                              >
                                {feature}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                        ):
                        (
                          <ul style={{ textAlign: "left", padding: "0 20px" }}>
                          {BusinessUserPlusFeatures.map((feature, i) => (
                            <li key={i}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.9em",
                                  fontWeight: 400,
                                  lineHeight: "1.5em",
                                  color: "lightSecondary.main",
                                  mt: 1,
                                }}
                              >
                                {feature}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                        )
                      }
                     
                    </Box>
              <Typography variant="h5" mt={2} sx={{ fontWeight: 600 , color:'secondary.main' }}>
                $999
              </Typography>
              <Typography 
              sx={{
                fontSize: "0.8rem",
                color: "lightSecondary.main",
                fontWeight: "400",
              }}
              >
                per year
              </Typography>
            </CardContent>
            <Box textAlign="center" pb={2}>
              <Button
                variant="contained"
                onClick={upgradeSubscription}
                sx={{
                  textTransform: "inherit",
                  width: "50%",
                  boxShadow: "none",
                  fontWeight: "600",
                }}
              >
                Upgrade
              </Button>
            </Box>
          </Card>
        </Grid>
        {/* Standard Plan */}
        <Grid item xs={12} md={6}>
          <Card
            sx={{
              // border: "1px solid #ddd",
              borderRadius: 2,
              boxShadow: 1,
              height: "100%",
              padding: 1,
            }}
          >
            <CardContent>
              <Typography variant="h5" 
               sx={{
                fontSize: '1.3rem',
                color: 'secondary.main',
                fontWeight: '700',
              }}
              >
                Pro
              </Typography>
              {/* <Typography
                variant="body2"
                sx={{
                  fontSize: "0.9rem",
                  color: "lightSecondary.main",
                  fontWeight: "500",
                }}
                mt={1}
                mb={2}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Suspendisse varius enim in eros elementum tristique.
              </Typography>
              <Box>
                <List sx={{display:'flex' , flexDirection:'column' , gap:1}}>
                  <ListItem
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <CheckIcon sx={{ color: "#5bb450" , fontSize:'1.5rem' , fontWeight:700  }} />
                    </ListItemIcon>
                    <Typography 
                    variant="body1"
                    sx={{
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "500",
                    }}
                    >
                      Feature label goes here
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <CheckIcon sx={{ color: "#5bb450" , fontSize:'1.5rem' , fontWeight:700  }} />
                    </ListItemIcon>
                    <Typography 
                    variant="body1"
                    sx={{
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "500",
                    }}
                    >
                      Feature label goes here
                    </Typography>
                  </ListItem>
                  <ListItem
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      padding: 0,
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 24 }}>
                      <CheckIcon sx={{ color: "#5bb450" , fontSize:'1.5rem' , fontWeight:700  }} />
                    </ListItemIcon>
                    <Typography 
                    variant="body1"
                    sx={{
                      fontSize: "0.9rem",
                      color: "lightSecondary.main",
                      fontWeight: "500",
                    }}
                    >
                      Feature label goes here
                    </Typography>
                  </ListItem>
                </List>
              </Box> */}
                 <Box sx={{mt:2}}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 700,
                          textAlign: "left",
                          fontSize: "0.9em",
                          color: "secondary.main",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Features Included:
                      </Typography>
                      {
                        userType == "solution_provider" ?
                        (
                          <ul style={{ textAlign: "left", padding: "0 20px" }}>
                          {SolutionProviderProFeatures.map((feature, i) => (
                            <li key={i}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.9em",
                                  fontWeight: 400,
                                  lineHeight: "1.5em",
                                  color: "lightSecondary.main",
                                  mt: 1,
                                }}
                              >
                                {feature}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                        ):
                        (
                          <ul style={{ textAlign: "left", padding: "0 20px" }}>
                          {BusinessUserProFeatures.map((feature, i) => (
                            <li key={i}>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "0.9em",
                                  fontWeight: 400,
                                  lineHeight: "1.5em",
                                  color: "lightSecondary.main",
                                  mt: 1,
                                }}
                              >
                                {feature}
                              </Typography>
                            </li>
                          ))}
                        </ul>
                        )
                      }
                    </Box>
              <Typography variant="h5" mt={2} sx={{ fontWeight: 600 , color:'secondary.main' }}>
                $4999
              </Typography>
              <Typography 
              sx={{
                fontSize: "0.8rem",
                color: "lightSecondary.main",
                fontWeight: "400",
              }}
              >
                per year
              </Typography>
            </CardContent>
            <Box textAlign="center" pb={2}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "inherit",
                  width: "50%",
                  boxShadow: "none",
                  fontWeight: "600",
                }}
                onClick={upgradeSubscription}
              >
                Upgrade
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
    //   {/* </Card> */}
    // {/* </Box> */}
  );
};

export default SubscribeModal;
