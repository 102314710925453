import { createSlice } from "@reduxjs/toolkit";

const boardSlice = createSlice({
    name :'board',
    initialState: {
        boardId: "",
        boardName: "",
        industryName: "",
    },
    reducers:{
        getBoardId: (state , action)=> {
          state.boardId =  action.payload
        },
        getBoardName: (state , action)=> {
          state.boardName =  action.payload
        },
        getIndustryName: (state , action)=>{
          state.industryName =  action.payload
        },
        clearBoardData: (state) => {
            state.boardId = "";
            state.boardName = "";
        },
        
    },
});

export const {getBoardId , getBoardName ,getIndustryName, clearBoardData} = boardSlice.actions;
export default boardSlice.reducer;