import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Add, Create } from '@mui/icons-material';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import AddIcon from "@mui/icons-material/Add";

const NoSolutionScreen = ({setOpenUpgradeModal , showToastMessage , handleCreateSolution}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const planName = useSelector((state) => state.user.planName);

    const handleSolution = () => {
      // Check the current path and navigate accordingly
      if (location.pathname === "/bsi-co-lab") {
        navigate("/my-solutions");
      } else if (location.pathname === "/my-solutions" ) {
         handleCreateSolution()
      } 
    };
    
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100% - 164px)"
            textAlign="center"
            padding={3}
        >
            {/* <img
                src="/path-to-your-image/no-data-illustration.png" // Replace with your image path
                // alt="No Data"
                style={{ width: '200px', height: 200, marginBottom: '20px', background: 'gray', borderRadius: 8 }} // Adjust the size as needed
            /> */}
            <Typography variant="h6" 
            sx={{
              color:"black",
              fontWeight: 600,
            }}
            gutterBottom
            >
                No solution added yet
            </Typography>
            <Typography 
            variant="body1" 
            sx={{
                color:"black",
                fontWeight: 500,  
              }}
            gutterBottom>
                You can start by creating a new solution
            </Typography>
            
            <Button
            onClick={handleSolution}
            variant="contained"
            sx={{
              textTransform: "inherit",
              mt:1,
              py: "11px",
              boxShadow: "none",
              px: 8.5,
              "&:hover": {
                boxShadow: "none",
              },
              [theme.breakpoints.down("md")]: {
                px: 2.5,
                width: "100%",
              },
            }}
          >
            <AddIcon
              sx={{
                fontSize: "20px",
                marginRight: "8px",
              }}
            />{" "}
            Create New Solution
          </Button>
            
        </Box>
    );
};

export default NoSolutionScreen;
