import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl =process.env.REACT_APP_base_URL;

const updateMeetingDetail = createApi({
    reducerPath: 'updateMeetingDetail',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateMeetingDetail: builder.mutation({
            query: ({ currentMeetingId, userId , meetingTitle , description,  meetingDate , meetingStartTime , meetingEndTime , participantsUserIds , meetingLink }) => ({
                url: `meetings/${currentMeetingId}`,
                method: 'PUT',
                body: { 
                    title: meetingTitle, 
                    owner_id:userId, 
                    date: meetingDate,
                    start_time: meetingStartTime,
                    end_time: meetingEndTime,
                    participants_user_id: participantsUserIds, 
                    meeting_link: meetingLink,
                    description: description,
             },
            }),
            transformResponse: (response) => {
            return response
            },
        }),
    }),
});

export const { useUpdateMeetingDetailMutation } = updateMeetingDetail;
export default updateMeetingDetail;
