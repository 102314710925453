import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const messageRead = createApi({
    reducerPath: 'messageRead',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchMessageRead: builder.query({
            query: ({ userId  , groupChatId}) => ({
                url:`message/read/allmessage?chatId=${groupChatId}`,
                method: 'GET',
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useFetchMessageReadQuery } = messageRead;
export default messageRead;


