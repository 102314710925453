import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { store } from '../../store/store';


const baseUrl = process.env.REACT_APP_base_URL;

export const requirementBoardApi  = createApi({
    reducerPath: 'requirementBoardApi',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchRequirementBoard: builder.query({
        query: ({ user_id, boardName, sort , industryFilterId, techFilterValue}) => ({
          url: `requirement_boards/boards?board_name=${boardName}&sort=${sort}&industry_filter=${industryFilterId}&tech_filter=${techFilterValue}`,
          method: 'GET',
        }),
        transformResponse: (response) => {

          if(response.status != "error"){
            return response.data.map(savedData => ({
              board_id: savedData?._id || "",
              userId: savedData?.user_id|| "",
              boardName: savedData?.board_name|| "",
              solutionId: savedData?.solution_id || [],
              techName: savedData?.tech_name|| "",
              industryName: savedData?.industry_name|| "",
              industryId: savedData?.industry_id|| "",
              useCaseName: savedData?.usecase_name|| "",
              useCaseId: savedData?.use_case_id|| "",
              dateAdded: savedData?.date_added|| "",
              updatedDate: savedData?.updated_at|| "",
              createdDate: savedData?.created_at|| "",
              boardTitle: savedData?.board_title|| "",
              solutionCount: savedData?.solution_count|| "",
            }));
          }else{
           return  response = [];
          }
     
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch user details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchRequirementBoardQuery } = requirementBoardApi;
export default requirementBoardApi;