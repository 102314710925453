import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const removeGroupUsers = createApi({
  reducerPath: "removeGroupUsers",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // const token = useSelector((state)=> state.user.token)
      const token = store.getState().user.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
  },
  }),
  endpoints: (builder) => ({
    removeUser: builder.mutation({
      query: ({ chatId, user_id }) => ({
        url: `chat/remove`,
        method: "DELETE",
        body: {
          chatId: chatId,
          userId: [user_id],
        },
      }),
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useRemoveUserMutation } = removeGroupUsers;
export default removeGroupUsers;
