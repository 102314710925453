import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";

const baseUrl =process.env.REACT_APP_base_URL;

const withDrawInvitation = createApi({
    reducerPath: 'withDrawInvitation',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        withDrawInvitation: builder.mutation({
            query: ({ boardId, userId, solutionId }) => ({
                url: `requirement_boards/withdraw-invitation`,
                method: 'POST',
                body: { 
                boardId: boardId, 
                userId: userId,
                solutionId:solutionId,
                },
            }),
            transformResponse: (response) => {
            return response
            },
        }),
    }),
});

export const { useWithDrawInvitationMutation } = withDrawInvitation;
export default withDrawInvitation;
