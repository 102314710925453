import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl =process.env.REACT_APP_base_URL;

const upgradePlan = createApi({
    reducerPath: 'upgradePlan',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        upgradeSubscriptionPlan: builder.mutation({
            query: ({  userId , upgradePlanName , userType }) => ({
                url: `user/upgrade_user`,
                method: 'POST',
                body: {
                    user_id: userId,
                    upgrade_slug: upgradePlanName,
                    user_type: userType
                }
            }),
            transformResponse: (response) => {
            return response
            },
        }),
    }),
});

export const { useUpgradeSubscriptionPlanMutation } = upgradePlan;
export default upgradePlan;
