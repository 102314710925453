import React from "react";
import { Grid, Typography, Skeleton, ListItem, ListItemAvatar, ListItemText } from "@mui/material";

const UpComingMeetingSkeleton = () => {
  const renderSkeletons = Array.from({ length: 5 });

  return (
    <Grid container mt={0.5} rowSpacing={1.9} columnSpacing={1}>
      <Grid item xs={12} md={2} lg={2}>
        <Skeleton
          variant="text"
          sx={{
            fontSize: "12px",
            height: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.11)",
            borderRadius: "4px",
            margin: "8px auto",
          }}
          width="60%"
        />
        <Skeleton
          variant="text"
          sx={{
            fontSize: "1.3rem",
            height: "30px",
            backgroundColor: "rgba(0, 0, 0, 0.11)",
            borderRadius: "4px",
            margin: "4px auto",
          }}
          width="80%"
        />
      </Grid>

      <Grid item xs={12} md={10} lg={10}>
        {renderSkeletons.map((_, index) => (
          <ListItem
            key={index}
            sx={{
              minHeight: "40px",
              alignItems: "center",
              py: "5px",
              background: "#FFFFFF",
              borderRadius: "10px",
              marginTop: "8px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ListItemAvatar
              sx={{
                display: "inline-block",
                marginRight: "7px",
              }}
            >
              <Skeleton
                variant="circular"
                width={40}
                height={40}
                sx={{
                  display: "block",
                  backgroundColor: "rgba(0, 0, 0, 0.11)",
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "0.9rem",
                    height: "20px",
                    backgroundColor: "rgba(0, 0, 0, 0.11)",
                    borderRadius: "4px",
                  }}
                  width="80%"
                />
              }
              secondary={
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "12px",
                    height: "15px",
                    backgroundColor: "rgba(0, 0, 0, 0.11)",
                    borderRadius: "4px",
                    marginTop: "4px",
                  }}
                  width="60%"
                />
              }
            />
          </ListItem>
        ))}
      </Grid>
    </Grid>
  );
};

export default UpComingMeetingSkeleton;
