import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../../store/store";

const baseUrl =process.env.REACT_APP_base_URL;

const updateSolutionDetail = createApi({
    reducerPath: 'updateSolutionDetail',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateSolutionDetail: builder.mutation({
            query: ({ userId, selectedSolutionId , formData}) => ({
                url: `solution/editSolution/${selectedSolutionId}`,
                method: 'PUT',
                body: formData,
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdateSolutionDetailMutation } = updateSolutionDetail;
export default updateSolutionDetail;
