import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const getDriveSubFolderList  = createApi({
    reducerPath: 'getDriveSubFolderList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchDriveSubFolderList: builder.query({
        query: ({userId , parentId , searchValue}) => ({
          url: `drive/sub-folders?parentId=${parentId}&search=${searchValue}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          // Extract relevant data from the response
         const { statusCode, status, message, data } = response;

         // Return the extracted data
         if(response.status != false){
          return {
            statusCode,
            status,
            message,
            subFolder: data
        };
         }else{
          return {
            statusCode,
            status,
            message,
            subFolder: []
        };
         }
   
     },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch folder detail error:', error);
  
        },
      })
    })
})


export const { useFetchDriveSubFolderListQuery } = getDriveSubFolderList;
export default getDriveSubFolderList;
