import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const UploadDocument = createApi({
  reducerPath: 'uploadDocument',
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      // const token = useSelector((state)=> state.user.token)
      const token = store.getState().user.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
  },
  }),
  endpoints: (builder) => ({
    uploadDocument: builder.mutation({
      query: (formData) => {
        return {
          url: 'drive/document-upload', // Replace with your API endpoint URL
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response) => {
        return response;
      },
    }),
  }),
});

export const { useUploadDocumentMutation } = UploadDocument;
export default UploadDocument;
