import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { store } from "../../store/store";

const baseUrl =process.env.REACT_APP_base_URL;

const updateSolutionProviderProfile = createApi({
    reducerPath: 'updateSolutionProviderProfile',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        updateSolutionProviderProfile: builder.mutation({
            query: ({ profileId, userId , userType , cityId,entityType, businessName ,companyFoundedData, businessUrl , phoneCode , phoneNumber , role , countryId ,  companySize , companyRevenue , fundingAmount, investmentStage,lookingForFunding }) => ({
                url:`/user/solution-provider/update-solution-provider-profile-form/${profileId}`,
                method: 'PUT',
                body: { 
                    user_id: userId,
                    user_type: userType,
                    business_name: businessName, 
                    business_url: businessUrl,
                    phone_code: phoneCode,
                    phone_number: phoneNumber,
                    company_role: role,
                    country_id: countryId, 
                    city_id: cityId,
                    entity_type: entityType,
                    company_founded_date: companyFoundedData,
                    funding_amount: fundingAmount,
                    company_size:companySize,
                    company_revenue_level: companyRevenue,
                    investment_stage: investmentStage,
                    looking_for_startup_funding: lookingForFunding,
             },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useUpdateSolutionProviderProfileMutation } = updateSolutionProviderProfile;
export default updateSolutionProviderProfile;
