import Search from '@mui/icons-material/Search';
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';


const baseUrl = process.env.REACT_APP_base_URL;

export const getDriveItemList  = createApi({
    reducerPath: 'getDriveItemList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchDriveItemList: builder.query({
        query: ({ parentId, searchParams,sortOption}) => ({
          url: `drive/drive_folders?search=${searchParams}&sort=${sortOption}&parentId=${parentId ? parentId : ""}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
         
          if(response.status != false){
            return response;
          }else{
            return []
          }
         },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch folder detail error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchDriveItemListQuery } = getDriveItemList;
export default getDriveItemList;
