import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';


const baseUrl = process.env.REACT_APP_base_URL;
const logoBaseUrl = 'https://bsdawebfiledata.s3.ca-central-1.amazonaws.com/solution/';

export const upComingMeetingList  = createApi({
    reducerPath: 'upComingMeetingList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchUpcomingMeetingList: builder.query({
        query: ({userId, searchParam }) => ({
          url: `meetings/upcoming?search=${searchParam}`,
          method: 'GET',
        }),
        transformResponse: (response) => {
          // Check if response.data is defined and is an array before accessing its length
          if (response?.data && Array.isArray(response.data) && response.data.length > 0) {
            return response;
          }
          // Handle cases where response.data is not defined or is an empty array
          return { data: [] };
        },
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch UpComingMeeting Details error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchUpcomingMeetingListQuery } = upComingMeetingList;
export default upComingMeetingList;
