import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';

const baseUrl = process.env.REACT_APP_base_URL

export const getIndustry = createApi({
    reducerPath: 'getIndustry',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        fetchIndustryList: builder.query({
            query: () => ({
                url: 'industry',
                method: 'GET',
            }),
            transformErrorResponse: (response) => {
                return response;
            },
            refetchOnWindowFocus: true,
            onError: (error) => {
                console.error('Fetch user details error:', error);

            },
        })
    })
})

export const {useFetchIndustryListQuery} = getIndustry;