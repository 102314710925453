import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { store } from "../../store/store";

const baseUrl = process.env.REACT_APP_base_URL;

const driveDocumentShare = createApi({
    reducerPath: 'driveDocumentShare',
    baseQuery: fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers) => {
            // const token = useSelector((state)=> state.user.token)
            const token = store.getState().user.token;
            if (token) {
              headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        DriveDocumentShare: builder.mutation({
            query: ({documentId ,selectedBoardId,selectedSolutionId, documentStatus,  selectedUsers , userId}) => ({
                url: `document-share/${documentId}`, // Replace with your API endpoint URL
                method: 'POST',
                body: { 
                    doc_owner_id: userId,
                    board_id: selectedBoardId,
                    solution_id: selectedSolutionId,
                    isDocument: documentStatus,
                    sharedWith: selectedUsers,
            },
            }),
            transformResponse: (response) => {
                return response;
            },
        }),
    }),
});

export const { useDriveDocumentShareMutation } = driveDocumentShare;
export default driveDocumentShare;
