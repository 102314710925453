import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Add, Create } from '@mui/icons-material';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const NoDataScreen = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isDrawerOpen = useSelector((state) => state.drawer.open);
    const handleGoBack = () => {
        window.location.reload(); 
      };
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100% - 164px)"
            textAlign="center"
            padding={3}
        >
            {/* <img
                src="/path-to-your-image/no-data-illustration.png" // Replace with your image path
                // alt="No Data"
                style={{ width: '200px', height: 200, marginBottom: '20px', background: 'gray', borderRadius: 8 }} // Adjust the size as needed
            /> */}
            <Typography variant="h6" 
            sx={{
              color:"black",
              fontWeight: 600,
            }}
            gutterBottom
            >
                No Data Available
            </Typography>
            <Typography 
            variant="body1" 
            sx={{
                color:"black",
                fontWeight: 500,  
              }}
            gutterBottom>
                It seems like there are no data available at the moment.
            </Typography>
            
            {/* <Button
              variant="contained"
              sx={{
                textTransform: "inherit",
                p: "10px 25px",
                boxShadow: "none",
                minWidth:'150px',
                minHeight:'30px',
                borderRadius:"10px",
                "&:hover": {
                  boxShadow: "none",
                },
                [theme.breakpoints.down("md")]: {
                  p: isDrawerOpen?"0px 7px":"9px 7px",
                },
                [theme.breakpoints.down("sm")]: {
                  p: "10px 25px",
                },
                [theme.breakpoints.down("xs")]: {
                  p: "10px 25px",
                },
              }}
              onClick={handleGoBack}
            >
              Go Back
            </Button> */}
            
        </Box>
    );
};

export default NoDataScreen;
