import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import { useSelector } from 'react-redux';
import { store } from '../../store/store';

const baseUrl = process.env.REACT_APP_base_URL;

export const getDriveDocumentList  = createApi({
    reducerPath: 'getDriveDocumentList',
    baseQuery: fetchBaseQuery({
      baseUrl: baseUrl,
      prepareHeaders: (headers) => {
        // const token = useSelector((state)=> state.user.token)
        const token = store.getState().user.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
    }),
    endpoints: (builder) => ({
      fetchDriveDocumentList: builder.query({
        query: (userId) => ({
          url: `drive/documents`,
          method: 'GET',
        }),
        transformResponse: (response) => {
         const driveFolder = response.data;
         return driveFolder.map((savedData)=>({
            id: savedData._id || "",
            name: savedData.name || "",
            isDocument: savedData.isDocument || false,
            parentId: savedData.parent_id ? (typeof savedData.parent_id === 'object' ? savedData.parent_id._id : savedData.parent_id) : null,
            ownerId: savedData.owner_id || "",
            isDeleted: savedData.isDeleted || false,
            createdAt: savedData.createdAt || "",
            updatedAt: savedData.updatedAt || "",
            viewUrl:  savedData.s3Url || ""
         }))
        },
        
        refetchOnWindowFocus: true,
        // retry: RetryOnError,
        onError: (error) => {
          console.error('Fetch folder detail error:', error);
          // Handle error cases as per your application's requirements
        },
      })
    })
})


export const { useFetchDriveDocumentListQuery } = getDriveDocumentList;
