import React from "react";
import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Skeleton,
} from "@mui/material";

const DocumentSkeletonLoader = () => {
  return Array.from({ length: 10 }).map((_, index) => (
    <Grid item xs={12} md={6} lg={6} key={index}>
      <ListItem sx={{ px: "0" }}>
        <ListItemAvatar>
          <Skeleton
            variant="rectangular"
            width={40}
            height={40}
            sx={{ borderRadius: "5px" }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="subtitle1" sx={{ fontSize: "0.9rem" }}>
              <Skeleton width="80%" />
            </Typography>
          }
          secondary={
            <Typography variant="body2" sx={{ fontSize: "12px" }}>
              <Skeleton width="60%" />
            </Typography>
          }
        />
        <Skeleton
          variant="circular"
          width={20}
          height={20}
          sx={{ marginLeft: "auto" }}
        />
      </ListItem>
    </Grid>
  ));
};

export default DocumentSkeletonLoader;
