import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CardActions,
  Divider,
  IconButton,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUpgradeSubscriptionPlanMutation } from "../../redux/api/MySubscription/upgradePlan";
import { setRefetchUserData } from "../../redux/features/refetch";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../../redux/features/loader";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useFetchAllSubscriptionQuery } from "../../redux/api/Users/allSubscription";
import PlanCardSkull from "../../commonComponents/Skeleton/PlanCardSkull";
import { useTheme } from "@emotion/react";
import Modals from "../../commonComponents/Modals";
import PaymentSuccess from "./paymentSuccess";

const MySubscription = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const invoices = [
    { date: "2024-11-01", plan: "Plus", amount: "$999", status: "Paid" },
    { date: "2024-10-01", plan: "Pro", amount: "$4999", status: "Paid" },
    { date: "2024-09-01", plan: "Pro", amount: "$999", status: "Paid" },
  ];
  const solutionProviderFeatures = [
    { name: "Number of Users", lite: true, plus: true, pro: true },
    { name: "Featured Listing", lite: true, plus: true, pro: true },
    { name: "Number of Solutions", lite: false, plus: true, pro: true },
    { name: "BSI Colab - Requirements (leads)", lite: true, plus: true, pro: true },
    { name: "BSI Colab - Messaging", lite: false, plus: true, pro: true },
    { name: "BSI Colab - Drive", lite: true, plus: true, pro: true },
    { name: "Support", lite: false, plus: true, pro: true },
    { name: "Product Video", lite: false, plus: true, pro: true },
  ];
  const businessUserFeatures = [
    { name: "Number of Users", lite: true, plus: true, pro: true },
    { name: "Featured Listing", lite: true, plus: true, pro: true },
    { name: "Discover & Compare AI Solutions", lite: true, plus: true, pro: true },
    { name: "Requirement Creation", lite: true, plus: true, pro: true },
    { name: "BSI Colab - Messaging", lite: false, plus: true, pro: true },
    { name: "BSI Colab - Drive", lite: true, plus: true, pro: true },
    { name: "Support", lite: false, plus: true, pro: true },
  ];
  
  // toast messages
  const showToastMessage = (message, type) => {
    if (type === "success") {
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else if (type === "info") {
      toast.warning(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  // State
  const laravelPricingPage = process.env.REACT_APP_LARAVEL_WEB_PRICING_URL;
  const userId = localStorage.getItem("userId");
  const userType = localStorage.getItem("userType");
  const planName = useSelector((state) => state.user.planName);
  const [billingCycle, setBillingCycle] = useState("monthly");
  const [openPaymentSuccess , setOpenPaymentSuccess] = useState(false)

  const handleOpenPaymentSuccessModal = ()=>{
    setOpenPaymentSuccess(true);
  }
  const handleClosePaymentSuccessModal = ()=>{
    setOpenPaymentSuccess(false);
  }

  const handleToggle = (event, newCycle) => {
    if (newCycle) {
      setBillingCycle(newCycle);
    }
  };
  // Api's
  const [
    upgradeSubscriptionPlan,
    { isLoading: upgradePlanLoading, isError: upgradePlanError },
  ] = useUpgradeSubscriptionPlanMutation();

  const {
    data: subscriptionData = { data: [] },
    isError: subscriptionError,
    isLoading: subscriptionLoading,
    refetch: refetchSubscription
  } = useFetchAllSubscriptionQuery();

  //  Business User Plans
  const businessUserPlans = {
    monthly: [
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[0] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[0] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_monthly.value)?.[0]} / month` || "",
        buttonText: "Get Started",
        features: [
          "Number of users who can access - 2",
          "Discover & Compare AI Solutions - Limited",
          "BSI Colab Requirement Creation - Limited",
          "BSI Colab Project Management - Demo video",
          "BSI Colab Messaging - Demo video",
          "BSI Colab Drive - 100 MB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Demo",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[1] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[1] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_monthly.value)?.[1]} / month` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - up to 5",
          "Discover & Compare AI Solutions - All Listing",
          "BSI Colab Requirement Creation - 5",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 1 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[2] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[2] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_monthly.value)?.[2]} / month` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - Unlimited",
          "Discover & Compare AI Solutions - All Listing",
          "BSI Colab Requirement Creation - Unlimited",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 10 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
    ],
    yearly: [
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[0] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[0] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_yearly.value)?.[0]} / year` || "",
        buttonText: "Get Started",
        features: [
          "Number of users who can access - 2",
          "Discover & Compare AI Solutions - Limited",
          "BSI Colab Requirement Creation - Limited",
          "BSI Colab Project Management - Demo video",
          "BSI Colab Messaging - Demo video",
          "BSI Colab Drive - 100 MB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Demo",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[1] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[1] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_yearly.value)?.[1]} / year` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - up to 5",
          "Discover & Compare AI Solutions - All Listing",
          "BSI Colab Requirement Creation - 5",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 1 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[2] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[2] || "",
        price: `$${subscriptionData?.data?.map((plan) => plan.price_yearly.value)?.[2]} / year` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - Unlimited",
          "Discover & Compare AI Solutions - All Listing",
          "BSI Colab Requirement Creation - Unlimited",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 10 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
    ],
  };

  // Solution Provider Plans
  const solutionProviderPlans = {
    monthly: [
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[0] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[0] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_monthly.value)?.[0]} / month` || "",
        buttonText: "Get Started",
        features: [
          "Number of users who can access - 2",
          "Featured Listing: Standard",
          "Number of Solutions: 1" ,
          "BSI Colab Project Management - Demo video",
          "BSI Colab Messaging - Demo video",
          "BSI Colab Drive - 100 MB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Demo",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[1] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[1] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_monthly.value)?.[1]} / month` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - up to 5",
          "Featured Listing: Silver ",
          "Number of Solutions: 5 ",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 1 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[2] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[2] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_monthly.value)?.[2]} / month` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - Unlimited",
          "Featured Listing: Gold",
          " Number of Solutions: Unlimited",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 10 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
    ],
    yearly: [
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[0] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[0] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_yearly.value)?.[0]} / year` || "",
        buttonText: "Get Started",
        features: [
          "Number of users who can access - 2",
          "Featured Listing: Standard ",
          "Number of Solutions: 1 ",
          "BSI Colab Project Management - Demo video",
          "BSI Colab Messaging - Demo video",
          "BSI Colab Drive - 100 MB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Demo",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[1] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[1] || "",
        price: ` $${subscriptionData?.data?.map((plan) => plan.price_yearly.value)?.[1]} / year` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - up to 5",
          "Featured Listing: Silver",
          "Number of Solutions: 5",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 1 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
      {
        planName: subscriptionData?.data?.map((plan) => plan.plan_slug)?.[2] || "",
        title: subscriptionData?.data?.map((plan) => plan.plan_name)?.[2] || "",
        price: `$${subscriptionData?.data?.map((plan) => plan.price_yearly.value)?.[2]} / year` || "",
        buttonText: "Start Free Trial",
        features: [
          "Number of users who can access - Unlimited",
          "Featured Listing: Gold",
          " Number of Solutions: Unlimited",
          "BSI Colab Project Management - Available",
          "BSI Colab Messaging - Available",
          "BSI Colab Drive - 10 GB",
          "BSI Colab Meeting - Available",
          "Developer Consultants - Available",
        ],
      },
    ],
  };

  // Functions

  const upgradeSubscription = async (newPlanName) => {
   if( newPlanName !=="lite" || planName !== newPlanName){
    try {
      const response = await upgradeSubscriptionPlan({
        userId,
        upgradePlanName: newPlanName,
        userType,
      }).unwrap();

      if (response.statusCode === "500") {
        showToastMessage("Plan Upgrade Failed");
      }

      const successMessage = "Plan Upgraded";
      showToastMessage(successMessage, "success");
      dispatch(setRefetchUserData());
      setOpenPaymentSuccess(true);
    } catch (error) {
      const errorMessage = error?.message || "Plan Upgrade Failed";
      showToastMessage(errorMessage, "error");
    }
  }
  };
  const handleGoToPrizingDetail = () => {
    window.location.href = laravelPricingPage;
  };

  useEffect(() => {
    if (upgradePlanLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [upgradePlanLoading, dispatch]);

  return (
    <>
      <Box>
        {/* <Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: "1.3rem",
              fontWeight: "600",
              color: "secondary.main",
            }}
          >
            Subscription Plans
          </Typography>

          <Grid container spacing={4} justifyContent="center" sx={{ mt: 0.5 }}>

            <Grid item xs={12} sm={6} md={4}>
              <Card
                variant="outlined"
                style={{
                  borderRadius: "12px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Lite
                    </Typography>
                    <IconButton onClick={handleGoToPrizingDetail}>
                      <Tooltip title="See Plan Detail">
                        <OpenInNewIcon sx={{ color: "lightSecondary.main" }} />
                      </Tooltip>
                    </IconButton>
                  </Box>
                  <Divider
                    style={{ margin: "1rem 0", backgroundColor: "#e0e0e0" }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    $0
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ color: "#757575", fontSize: "1rem" }}
                    >
                      per month
                    </Typography>
                  </Typography>
                  <Box sx={{ paddingTop: 1 }}>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <ClearIcon sx={{ color: "red.main", fontSize: "23px" }} />
                      <span>BsiCoLab Messaging</span>
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <CheckIcon sx={{ color: "#347928", fontSize: "23px" }} />
                      <span>BsiCoLab Meeting</span>
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <CheckIcon sx={{ color: "#347928", fontSize: "23px" }} />
                      <span>Developer Consultant</span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      width: "100%",
                    }}
                  >
                    {planName == "lite" ? (
                      <Button
                        variant="contained"
                        disabled
                        sx={{
                          textTransform: "inherit",
                          width: "100%",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                      >
                        You are on Free plan
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "inherit",
                          width: "100%",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                      >
                        Subscribe
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <Card
                variant="outlined"
                style={{
                  borderRadius: "12px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Plus
                    </Typography>
                    <IconButton onClick={handleGoToPrizingDetail}>
                      <Tooltip title="See Plan Detail">
                        <OpenInNewIcon sx={{ color: "lightSecondary.main" }} />
                      </Tooltip>
                    </IconButton>
                  </Box>
                  <Divider
                    style={{ margin: "1rem 0", backgroundColor: "#e0e0e0" }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    $999
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ color: "#757575", fontSize: "1rem" }}
                    >
                      per year
                    </Typography>
                  </Typography>
                  <Box sx={{ paddingTop: 1 }}>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <ClearIcon sx={{ color: "red.main", fontSize: "23px" }} />
                      <span>BsiCoLab Messaging</span>
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <CheckIcon sx={{ color: "#347928", fontSize: "23px" }} />
                      <span>BsiCoLab Meeting</span>
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <CheckIcon sx={{ color: "#347928", fontSize: "23px" }} />
                      <span>Developer Consultant</span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      width: "100%",
                    }}
                  >
                    {planName == "plus" ? (
                      <Button
                        variant="contained"
                        disabled
                        sx={{
                          textTransform: "inherit",
                          width: "100%",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                      >
                        You are on Plus plan
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "inherit",
                          width: "100%",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                        onClick={upgradeSubscriptionToPlus}
                      >
                        Subscribe
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>


            <Grid item xs={12} sm={6} md={4}>
              <Card
                variant="outlined"
                style={{
                  borderRadius: "12px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                <CardContent>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      style={{ fontWeight: "bold" }}
                    >
                      Pro
                    </Typography>
                    <IconButton onClick={handleGoToPrizingDetail}>
                      <Tooltip title="See Plan Detail">
                        <OpenInNewIcon sx={{ color: "lightSecondary.main" }} />
                      </Tooltip>
                    </IconButton>
                  </Box>
                  <Divider
                    style={{ margin: "1rem 0", backgroundColor: "#e0e0e0" }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                      fontWeight: "bold",
                      display: "flex",
                      // justifyContent: 'center',
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    $4999
                    <Typography
                      variant="body2"
                      component="span"
                      style={{ color: "#757575", fontSize: "1rem" }}
                    >
                      per year
                    </Typography>
                  </Typography>
                  <Box sx={{ paddingTop: 1 }}>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <CheckIcon sx={{ color: "#347928", fontSize: "23px" }} />
                      <span>BsiCoLab Messaging</span>
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <CheckIcon sx={{ color: "#347928", fontSize: "23px" }} />
                      <span>BsiCoLab Meeting</span>
                    </Typography>
                    <Typography
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        marginBottom: "0.5rem",
                      }}
                    >
                      <CheckIcon sx={{ color: "#347928", fontSize: "23px" }} />
                      <span>Developer Consultant</span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      width: "100%",
                    }}
                  >
                    {planName == "pro" ? (
                      <Button
                        variant="contained"
                        disabled
                        sx={{
                          textTransform: "inherit",
                          width: "100%",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                      >
                        You are on Pro plan
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "inherit",
                          width: "100%",
                          boxShadow: "none",
                          fontWeight: "600",
                        }}
                        onClick={upgradeSubscriptionToPro}
                      >
                        Subscribe
                      </Button>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box> */}
        {/* Toggle Button */}
        <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Typography
            variant="h6"
            align="center" 
            gutterBottom
            sx={{
              fontSize: "1.6em",
              fontWeight: "700",
              color: "secondary.main",
            }}
          >
            Choose the Right Plan for Your Business Goals
          </Typography>
          <Typography
            variant="h6"
            align="center" 
            gutterBottom
            sx={{
              fontSize: "1em",
              fontWeight: "500",
              color: "#106eed",
            }}
          >
           Empower your solutions with curated visibility, efficient lead management, and streamlined collaboration tools. Find the plan that fits your business needs and unlock new opportunities.
          </Typography>
          <Box sx={{ textAlign: "center", marginBottom: 3 , mt:2 }}>
            <ToggleButtonGroup
              value={billingCycle}
              exclusive
              onChange={handleToggle}
            >
              <ToggleButton
                value="monthly"
                sx={(theme) => ({
                  borderRadius: "10px",
                  textTransform: "none",
                  backgroundColor:
                    billingCycle === "monthly"
                      ? "#073985  !important"
                      : "#fff  !important",
                  color:
                    billingCycle === "monthly"
                      ? "#fff !important"
                      : "lightSecondary.main !important",
                  padding: "10px 20px",
                  "&:hover": {
                    backgroundColor:
                      billingCycle === "monthly"
                        ? "#073985  !important"
                        : "#fff  !important",
                    color:
                      billingCycle === "monthly"
                        ? "#fff !important"
                        : "lightSecondary.main !important",
                  },
                })}
              >
                Paid Monthly
              </ToggleButton>
              <ToggleButton
                value="yearly"
                sx={(theme) => ({
                  borderRadius: "10px",
                  textTransform: "none",
                  backgroundColor:
                    billingCycle === "yearly"
                      ? "#073985  !important"
                      : "#fff  !important",
                  color:
                    billingCycle === "yearly"
                      ? "#fff !important"
                      : "lightSecondary.main !important",
                  padding: "10px 20px",
                  "&:hover": {
                    backgroundColor:
                      billingCycle === "yearly"
                        ? "#073985  !important"
                        : "#fff  !important",
                    color:
                      billingCycle === "yearly"
                        ? "#fff !important"
                        : "lightSecondary.main !important",
                  },
                })}
              >
                Paid Yearly
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
           {/* Cards  */}
          <Grid container spacing={3}>
            {subscriptionLoading ?
             (
              <>
              <PlanCardSkull/>
              </>
              ) :
           (
              userType == "solution_provider" ?
              (
                solutionProviderPlans[billingCycle].map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#ecf6ff",
                        border: "1px solid #001a8c",
                        borderRadius: "10px",
                        transition: "all 0.5s ease-in-out 0s",
                        "&:hover": {
                          background: "#ffffff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: "1.2em",
                            fontWeight: 700,
                            lineHeight: "1.2em",
                            color: "secondary.main",
                            mb: 1,
                          }}
                        >
                          {plan.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1.2em",
                            fontWeight: 500,
                            lineHeight: "1.4em",
                            color: "lightSecondary.main",
                            mb: 1,
                          }}
                        >
                          {plan.price}
                        </Typography>
                        <Button
                          variant="contained"
                          color={
                            planName === plan.planName ? "secondary" : "primary"
                          }
                          sx={{ textTransform: "none", mb: 2 }}
                          disabled={planName === plan.planName || plan.planName === "lite"}
                          onClick={()=>upgradeSubscription(plan.planName)}
                        >
                          {planName === plan.planName ? "Current" : plan.buttonText}
                        </Button>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: "0.9em",
                              color: "secondary.main",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            Features Included:
                          </Typography>
                          <ul style={{ textAlign: "left", padding: "0 20px" }}>
                            {plan.features.map((feature, i) => (
                              <li key={i}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9em",
                                    fontWeight: 400,
                                    lineHeight: "1.5em",
                                    color: "lightSecondary.main",
                                    mt: 1,
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              )
              :
              (
                businessUserPlans[billingCycle].map((plan, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Card
                      sx={{
                        textAlign: "center",
                        backgroundColor: "#ecf6ff",
                        border: "1px solid #001a8c",
                        borderRadius: "10px",
                        transition: "all 0.5s ease-in-out 0s",
                        "&:hover": {
                          background: "#ffffff",
                          boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
                        },
                      }}
                    >
                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: "1.2em",
                            fontWeight: 700,
                            lineHeight: "1.2em",
                            color: "secondary.main",
                            mb: 1,
                          }}
                        >
                          {plan.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1.2em",
                            fontWeight: 500,
                            lineHeight: "1.4em",
                            color: "lightSecondary.main",
                            mb: 1,
                          }}
                        >
                          {plan.price}
                        </Typography>
                        <Button
                          variant="contained"
                          color={
                            planName === plan.planName ? "secondary" : "primary"
                          }
                          sx={{ textTransform: "none", mb: 2 }}
                          disabled={planName === plan.planName || plan.planName === "lite"}
                          onClick={()=>upgradeSubscription(plan.planName)}
                        >
                          {planName === plan.planName ? "Current" : plan.buttonText}
                        </Button>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 700,
                              textAlign: "left",
                              fontSize: "0.9em",
                              color: "secondary.main",
                              fontFamily: "Poppins, sans-serif",
                            }}
                          >
                            Features Included:
                          </Typography>
                          <ul style={{ textAlign: "left", padding: "0 20px" }}>
                            {plan.features.map((feature, i) => (
                              <li key={i}>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontSize: "0.9em",
                                    fontWeight: 400,
                                    lineHeight: "1.5em",
                                    color: "lightSecondary.main",
                                    mt: 1,
                                  }}
                                >
                                  {feature}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              )
           )
          
           }
          
          </Grid>
        </Box>
        {/* Comparison Table */}
        {
          userType == "solution_provider" ? 
           (
            <Box sx={{ padding: 3 }}>
            <Typography
                variant="h6"
                align="center" 
                gutterBottom
                sx={{
                  fontSize: "1.6em",
                  fontWeight: "700",
                  color: "secondary.main",
                }}
              >
                Compare Plans
              </Typography>
              <Typography
                variant="h6"
                align="center" 
                gutterBottom
                sx={{
                  fontSize: "1em",
                  fontWeight: "500",
                  color: "#106eed",
                }}
              >
               Empower your solutions with curated visibility, efficient lead management, and streamlined collaboration tools. Find the plan that fits your business needs and unlock new opportunities.
              </Typography>
          <TableContainer component={Paper}
          sx={{
            backgroundColor: "#ecf6ff",
            border: "1px solid #001a8c",
            borderRadius: "10px",
            mt:2,
            transition: "all 0.5s ease-in-out 0s",
            "&:hover": {
              background: "#ffffff",
              boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
            },
          }}
          >
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Features
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Lite
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Plus
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Pro
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solutionProviderFeatures.map((feature, index) => (
                  <TableRow key={index}>
                    <TableCell 
                    sx={{ 
                      border: "1px solid #ddd",
                        fontWeight: 600,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize:'0.9em',
                        lineHeight: '1.2em',
                        color:"secondary.main",
                        [theme.breakpoints.down('md')]: {
                          fontSize:'0.7em',
                          lineHeight: '1.2em',
                        },
                    }}>
                      {feature.name}
                    </TableCell>
                    <TableCell align="center" sx={{ border: "1px solid #ddd" }}>
                      {feature.lite ? (
                        <CheckIcon sx={{ color: "green.main" , fontSize:'1.2em' }} />
                      ) : (
                        <CloseIcon sx={{ color: "secondary.main" , fontSize:'1.2em' }} />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={{ border: "1px solid #ddd" }}>
                      {feature.plus ? (
                        <CheckIcon sx={{ color: "green.main" , fontSize:'1.2em' }} />
                      ) : (
                        <CloseIcon sx={{ color: "secondary.main" , fontSize:'1.2em'}} />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={{ border: "1px solid #ddd" }}>
                      {feature.pro ? (
                         <CheckIcon sx={{ color: "green.main" , fontSize:'1.2em'}} />
                      ) : (
                        <CloseIcon sx={{ color: "secondary.main" , fontSize:'1.2em' }} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            </Box>
           ):
           (
            <Box sx={{ padding: 3 }}>
            <Typography
                variant="h6"
                align="center" 
                gutterBottom
                sx={{
                  fontSize: "1.6em",
                  fontWeight: "700",
                  color: "secondary.main",
                }}
              >
                Compare Plans
              </Typography>
              <Typography
                variant="h6"
                align="center" 
                gutterBottom
                sx={{
                  fontSize: "1em",
                  fontWeight: "500",
                  color: "#106eed",
                }}
              >
               Empower your solutions with curated visibility, efficient lead management, and streamlined collaboration tools. Find the plan that fits your business needs and unlock new opportunities.
              </Typography>
          <TableContainer component={Paper}
          sx={{
            backgroundColor: "#ecf6ff",
            border: "1px solid #001a8c",
            borderRadius: "10px",
            mt:2,
            transition: "all 0.5s ease-in-out 0s",
            "&:hover": {
              background: "#ffffff",
              boxShadow: "0px 4px 52px rgba(172, 172, 172, 0.25)",
            },
          }}
          >
            <Table sx={{ borderCollapse: "collapse" }}>
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Features
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Lite
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Plus
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontWeight: 700,
                      border: "1px solid #ddd",
                      width: "25%",
                      fontSize:'1.2em',
                      lineHeight: '1.2em',
                      color:"secondary.main"
                    }}
                  >
                    Pro
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {businessUserFeatures.map((feature, index) => (
                  <TableRow key={index}>
                    <TableCell 
                    sx={{ 
                      border: "1px solid #ddd",
                        fontWeight: 600,
                        border: "1px solid #ddd",
                        width: "25%",
                        fontSize:'0.9em',
                        lineHeight: '1.2em',
                        color:"secondary.main",
                        [theme.breakpoints.down('md')]: {
                          fontSize:'0.7em',
                          lineHeight: '1.2em',
                        },
                    }}>
                      {feature.name}
                    </TableCell>
                    <TableCell align="center" sx={{ border: "1px solid #ddd" }}>
                      {feature.lite ? (
                        <CheckIcon sx={{ color: "green.main" , fontSize:'1.2em' }} />
                      ) : (
                        <CloseIcon sx={{ color: "secondary.main" , fontSize:'1.2em' }} />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={{ border: "1px solid #ddd" }}>
                      {feature.plus ? (
                        <CheckIcon sx={{ color: "green.main" , fontSize:'1.2em' }} />
                      ) : (
                        <CloseIcon sx={{ color: "secondary.main" , fontSize:'1.2em'}} />
                      )}
                    </TableCell>
                    <TableCell align="center" sx={{ border: "1px solid #ddd" }}>
                      {feature.pro ? (
                         <CheckIcon sx={{ color: "green.main" , fontSize:'1.2em'}} />
                      ) : (
                        <CloseIcon sx={{ color: "secondary.main" , fontSize:'1.2em' }} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
            </Box>
           )
        }
       
        {/* Invoice History Section */}
        {/* <Box style={{ marginTop: "2rem" }}>
          <Typography
            variant="h6"
            align="center" 
            gutterBottom
            sx={{
              fontSize: "1.3rem",
              fontWeight: "600",
              color: "secondary.main",
            }}
          >
            Invoice History
          </Typography>
          <TableContainer sx={{ mt: 3 }} component={Paper}>
            <Table>
              <TableHead sx={{ bgcolor: "secondary.main" }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>Date</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Plan</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Amount</TableCell>
                  <TableCell sx={{ color: "#fff" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.date}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.plan}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.amount}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "600" }}>
                      {invoice.status}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box> */}

        {/* FAQ Section */}
        <Box sx={{ marginTop: "2rem", marginBottom: "2rem" , }}>
          <Typography
            variant="h6"
            align="center" 
            gutterBottom
            sx={{
              fontSize: "1.6rem",
              fontWeight: "700",
              color: "secondary.main",
              mb:2
            }}
          >
            Frequently Asked Questions
          </Typography>
          {/* Question1 */}
          <Accordion
            sx={{
              mt: 3,
              borderRadius: "15px",
              overflow: "hidden",
              border: "1px solid #001a8c",
              "&:first-of-type": {
                marginTop: 0,
                borderRadius: "10px 10px 10px 10px", // Adjust for specific border-radius
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontWeight: "500" , color:'secondary.main' }}>
                What is the difference between LITE, PLUS, and PRO plans?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',color: "#106eed", }}>
                The LITE plan is free and ideal for smaller teams looking to
                test the platform with limited features. PLUS and PRO plans
                offer advanced features like increased lead management, project
                collaboration tools, and enhanced visibility with featured
                listings.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question2 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "500" ,  color:'secondary.main' }}>
                {" "}
                Can I upgrade or downgrade my plan at any time?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',  color: "#106eed", }}>
                Yes, you can easily upgrade or downgrade your plan based on your
                business needs. Upgrades are immediate, while downgrades will
                take effect at the end of the current billing cycle.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question3 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "500" , color:'secondary.main' }}>
                How does the featured listing work?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',  color: "#106eed", }}>
                Featured listings improve your solution's visibility in
                searches. Silver and Gold tiers ensure prioritized placement,
                driving more inquiries and potential leads.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question4 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "500" , color:'secondary.main' }}>
                Is there a free trial for the PLUS or PRO plans?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',  color: "#106eed", }}>
                Currently, we do not offer a free trial for the PLUS or PRO
                plans. However, the LITE plan allows you to explore the
                platform’s basic features for free..
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question5 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
               <Typography sx={{ fontWeight: "500" , color:'secondary.main' }}>
                {" "}
                What payment methods do you accept?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',  color: "#106eed", }}>
                We accept payments via credit/debit cards, and all transactions
                are securely processed through the Stripe payment gateway.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question6 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
               <Typography sx={{ fontWeight: "500" ,  color:'secondary.main' }}>
                How does BSI Co-lab help with lead management?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,fontSize:'0.9em',  color: "#106eed", }}>
                BSI Co-lab simplifies lead management by allowing you to view
                and respond to inquiries, track the status of leads, and
                collaborate with potential business partners—all in one place.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question7 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
               <Typography sx={{ fontWeight: "500" ,  color:'secondary.main' }}>
                {" "}
                What happens to my data if I downgrade or cancel my plan?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',  color: "#106eed", }}>
                Your data remains secure. If you downgrade or cancel, your
                stored files and information will still be accessible but
                subject to the limits of your new plan.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question8 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
               <Typography sx={{ fontWeight: "500" , color:'secondary.main' }}>
                {" "}
                Can I add more users to my account?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',  color: "#106eed", }}>
                Yes, you can add users to your account based on the limits of
                your plan. The PRO plan supports up to 15 users, while PLUS
                allows up to 5.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question9 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "10px",
              overflow: "hidden",
              border: "1px solid #001a8c",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
               <Typography sx={{ fontWeight: "500" , color:'secondary.main' }}>
                Is my data secure on BSDA?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" ,  fontSize:'0.9em',  color: "#106eed", }}>
                Absolutely! We use AWS cloud services to ensure the highest
                levels of security for all your documents, communication, and
                business data.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {/* Question10 */}
          <Accordion
            sx={{
              mt: 2,
              borderRadius: "15px",
              overflow: "hidden",
              borderRadius: "10px 10px 10px 10px",
              border: "1px solid #001a8c",
              "&:last-of-type": {
                marginTop: 2,
                borderRadius: "10px 10px 10px 10px", // Adjust for specific border-radius
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
               <Typography sx={{ fontWeight: "500" , color:'secondary.main' }}>
                Who can I contact for support or further questions?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontWeight: "400" , fontSize:'0.9em',  color: "#106eed", }}>
                You can reach out to our support team via email or through the
                contact form in the dashboard. Our team is happy to assist you
                with any questions or issues.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Modals
        open={openPaymentSuccess}
        handleClose={handleClosePaymentSuccessModal}
        modalWidth={450}
      >
        <PaymentSuccess
          handleClose={handleClosePaymentSuccessModal}
        />
      </Modals>
    </>
  );
};

export default MySubscription;
